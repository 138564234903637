<div class="candidate_details">
  <div class="top-row d-flex justify-content-between">
    <div class="top-left-details w-60 d-flex align-middle">
      <div class="top_profile">
        <div class="container1">
          <img
            *ngIf="url !== null"
            [src]="url"
            height="120"
            width="120"
            class="clientlogobgimg img-fluid image1"
            alt=""
          />
          <a
            class="edit-icon1"
            (click)="selectFile1.click()"
            style="padding-right: 5px; padding-left: 5px; background: white"
          >
            <em class="bi bi-camera-fill" title="Edit Image"></em>
          </a>
          <input
            type="file"
            id="file-input1"
            height="120"
            width="120"
            style="display: none"
            accept="image/*"
            #selectFile1
            max-file-size="1024"
            name="logo"
            class="form-control clientlogobgimg img-fluid image1"
            (change)="attachLogoFiles($event)"
          />
        </div>
      </div>

      <div class="top_right_profile ps-3 d-inline-block">
        <h3 class="d-inline-block align-middle m-0">
          {{
            (candidateData?.in_first_name ? candidateData?.in_first_name : "") +
              "
          " +
              (candidateData?.in_last_name ? candidateData?.in_last_name : "")
          }}
        </h3>

        <a
          *ngIf="isself_created !== 1"
          class="blue_link"
          href="javascript:void(0)"
          >{{
            "Added by
          " + (candidateData?.creator?.name ? candidateData?.creator?.name : "")
          }}</a
        >

        <a
          *ngIf="isself_created === 1"
          class="blue_link"
          href="javascript:void(0)"
          >Added by Self</a
        >

        <p>
          {{ candidateData?.current_position }} -
          {{ candidateData?.current_company }}
        </p>
        <div class="custom-row">
          <a
            href="javascript:void(0)"
            class="blue_link position-absolute"
            (click)="open(Addtag, 'Addtag')"
            title="Click to add tag"
            >+ Tags</a
          >

          <div class="tagmain" style="padding-left: 42px">
            <a
              *ngFor="let tag of candidatetagList"
              href="javascript:void(0)"
              [ngClass]="tag.name ? 'light-blue-btn' : ''"
              >{{ tag?.name }}
              <span class="close-sign" (click)="clearTags(tag.id)">{{
                tag.name ? "X" : ""
              }}</span></a
            >
          </div>
        </div>
      </div>
    </div>

    <div class="top-right-details w-50 text-end">
      <p
        style="cursor: pointer"
        title="Click to refresh data from LinkedIn"
        *ngIf="candidateData?.linkedin_url"
      >
        Last refresh from Linkedin
        {{ candidateData?.refreshed_at | date : "MM/dd/yyyy" }}
        <a href="javascript:void(0)"
          ><img
            src="./assets/img/reload.svg"
            (click)="linkedinRefresh(popupForLinkedinContent)"
            class="reload me-0"
            alt=""
        /></a>
      </p>
      <a
        class="custom-dots-btn"
        style="cursor: pointer"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <img src="./assets/img/dots.svg" alt="" />
      </a>

      <ul class="dropdown-menu">
        <li>
          <a
            *ngIf="candidateData?.linkedin_url"
            class="dropdown-item"
            style="cursor: pointer"
            data-bs-toggle="modal"
            data-bs-target="#createBackdrop"
            (click)="linkedinRefresh(popupForLinkedinContent)"
            >Refresh from LinkedIn</a
          >
        </li>
        <li>
          <a
            class="dropdown-item"
            style="cursor: pointer"
            (click)="
              open(
                DeleteCandidate,
                'DeleteCandidate',
                1,
                candidateData.in_first_name
              )
            "
            >Delete Candidate</a
          >
        </li>
      </ul>
      <div>
        <a
          class="filter f-gray me-3"
          style="cursor: pointer"
          title="Click to go back"
          (click)="returnToBack()"
          ><em class="bi bi-arrow-left-short"></em> Back</a
        >
      </div>
    </div>
  </div>

  <div class="row details-bottom-table">
    <div class="accordion col-4" id="accordionPanelsStayOpenExample">
      <div class="accordion-item mb-3 rounded overflow-hidden">
        <h2 class="accordion-header" id="panelsStayOpen-headingOne">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseOne"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapseOne"
          >
            Contact Profile
          </button>
          <a href="javascript:void(0)" class="edit"
            ><img
              src="./assets/img/edit.svg"
              title="Edit"
              (click)="open(UpdateCandidateDetails, 'UpdateCandidateDetails')"
              alt=""
          /></a>
        </h2>
        <div
          id="panelsStayOpen-collapseOne"
          class="accordion-collapse collapse show"
          aria-labelledby="panelsStayOpen-headingOne"
          title="Expand/Collapse"
        >
          <div class="accordion-body">
            <ul class="row list-unstyled">
              <li class="col-5">
                Full Name
                <span
                  *ngIf="
                    candidateData?.is_in_first_name_modified ||
                    candidateData?.is_in_last_name_modified
                  "
                  style="color: blue"
                  >#</span
                >
              </li>
              <li class="col-7">
                {{
                  candidateData?.in_first_name +
                    " " +
                    candidateData?.in_last_name
                }}
              </li>
              <li class="col-5">Strength</li>
              <li class="col-7">
                <img
                  *ngIf="candidateData?.strength"
                  src="{{ './assets/img/' + candidateData?.strength + '.png' }}"
                  alt=""
                />
                <span *ngIf="!candidateData?.strength">---</span>
              </li>
              <li class="col-5">
                Current Company
                <span
                  *ngIf="candidateData?.is_current_company_modified"
                  style="color: blue"
                  >#</span
                >
              </li>
              <li style="position: relative" class="col-7">
                <a
                  href="javascript:void(0)"
                  (click)="searchPeopleInCompany()"
                  >{{
                    candidateData?.current_company
                      ? candidateData?.current_company
                      : "---"
                  }}</a
                >
                <button
                  *ngIf="candidateData?.current_company && !is_client"
                  style="display: block"
                  (click)="open(createClient, 'createClient')"
                  type="button"
                  class="blue-btn save-contact-btn"
                >
                  Create Client
                </button>
              </li>
              <li class="col-5">
                Current Position
                <span
                  *ngIf="candidateData?.is_current_position_modified"
                  style="color: blue"
                  >#</span
                >
              </li>
              <li class="col-7">
                {{
                  candidateData?.current_position
                    ? candidateData?.current_position
                    : "---"
                }}
              </li>
              <li class="col-5">
                Birth date
                <span
                  *ngIf="candidateData?.is_in_birth_date_modified"
                  style="color: blue"
                  >#</span
                >
              </li>
              <li class="col-7">
                {{
                  candidateData?.in_birth_date
                    ? (candidateData?.in_birth_date | date : "MM/dd/yyyy")
                    : "---"
                }}
              </li>
              <li class="col-5">
                Language
                <span
                  *ngIf="candidateData?.is_languages_modified"
                  style="color: blue"
                  >#</span
                >
              </li>
              <li class="col-7">
                <span>{{ candidateData?.languages_names }}</span>
              </li>
              <li class="col-5">Linked-In</li>
              <li class="col-7">
                <span *ngIf="!candidateData?.linkedin_url"> --- </span
                ><a
                  *ngIf="candidateData?.linkedin_url"
                  href="{{ candidateData?.linkedin_url }}"
                  title=""
                  target="_blank"
                  >{{
                    candidateData?.linkedin_url
                      ? "Open LinkedIn Profile"
                      : "---"
                  }}
                  <span
                    *ngIf="candidateData?.linkedin_url !== null"
                    style="width: 24px; height: 24px"
                    ><img
                      src="assets/img/linkedin-logo.png"
                      style="width: 24px; height: 24px"
                      alt=""
                    /> </span
                ></a>
              </li>
              <li class="col-5">Source</li>
              <li class="col-7">
                {{ candidateData?.source ? candidateData.source : "---" }}
              </li>
              <li class="col-5">
                Gender
                <span
                  *ngIf="candidateData?.is_in_gender_modified"
                  style="color: blue"
                  >#</span
                >
              </li>
              <li class="col-7">
                {{ capitalizeFirstLetter(candidateData?.in_gender) }}
              </li>
              <li class="col-5">Diverse Candidate</li>
              <li class="col-7">
                <span *ngIf="candidateData?.custom_diversity === null"
                  >---</span
                >
                <span *ngIf="candidateData?.custom_diversity === 0">No</span>
                <span *ngIf="candidateData?.custom_diversity === 1">Yes</span>
              </li>
              <li class="col-5">Compensation</li>
              <li class="col-7">
                {{
                  candidateData?.custom_compensation_details
                    ? candidateData.custom_compensation_details
                    : "---"
                }}
              </li>
            </ul>
            <p style="font-size: 12px">
              <span style="color: blue">#</span> This data was modified by the
              User and not refreshed from LinkedIn.
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item mb-3 rounded overflow-hidden">
        <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseTwo"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapseTwo"
          >
            Contact Details
          </button>
          <a
            href="javascript:void(0)"
            class="edit"
            title="Edit"
            (click)="open(UpdateContactDetails, 'UpdateContactDetails')"
            ><img src="./assets/img/edit.svg" alt=""
          /></a>
        </h2>
        <div
          id="panelsStayOpen-collapseTwo"
          class="accordion-collapse collapse show"
          aria-labelledby="panelsStayOpen-headingTwo"
        >
          <div class="accordion-body">
            <ul class="row list-unstyled">
              <li class="col-5">
                Personal Phone
                <span
                  *ngIf="candidateData?.is_in_personal_phone_modified"
                  style="color: blue"
                  >#</span
                >
              </li>
              <li class="col-7">
                <img
                  title="preferred phone"
                  *ngIf="candidateData?.is_in_personal_phone_preffered"
                  src="./assets/img/preferred.svg"
                  style="width: 16px; height: auto"
                  alt=""
                />{{
                  candidateData?.in_personal_phone
                    ? candidateData?.in_personal_phone
                    : "---"
                }}
              </li>

              <li class="col-5">
                Personal Email
                <span
                  *ngIf="candidateData?.is_in_personal_email_modified"
                  style="color: blue"
                  >#</span
                >
              </li>

              <li class="col-7">
                <img
                  *ngIf="candidateData?.is_in_personal_email_preffered"
                  src="./assets/img/preferred.svg"
                  style="width: 16px; height: auto"
                  alt=""
                /><a href="mailto:{{ candidateData?.in_personal_email }}">{{
                  candidateData?.in_personal_email
                    ? candidateData?.in_personal_email
                    : "---"
                }}</a>
              </li>

              <li class="col-5">
                Work Phone
                <span
                  *ngIf="candidateData?.is_in_work_phone_modified"
                  style="color: blue"
                  >#</span
                >
              </li>
              <li class="col-7">
                <img
                  *ngIf="candidateData?.is_in_work_phone_preffered"
                  src="./assets/img/preferred.svg"
                  style="width: 16px; height: auto"
                  alt=""
                />{{
                  candidateData?.in_work_phone
                    ? candidateData?.in_work_phone
                    : "---"
                }}
              </li>
              <li class="col-5">
                Work Email
                <span
                  *ngIf="candidateData?.is_in_work_email_modified"
                  style="color: blue"
                  >#</span
                >
              </li>
              <li class="col-7">
                <img
                  *ngIf="candidateData?.is_in_work_email_preffered"
                  src="./assets/img/preferred.svg"
                  style="width: 16px; height: auto"
                  alt=""
                /><a href="mailto:{{ candidateData?.in_work_email }}">{{
                  candidateData?.in_work_email
                    ? candidateData?.in_work_email
                    : "---"
                }}</a>
              </li>
              <li class="col-5">
                Address
                <span
                  *ngIf="
                    candidateData?.is_in_city_modified ||
                    candidateData?.is_in_state_modified
                  "
                  style="color: blue"
                  >#</span
                >
              </li>
              <li class="col-7">
                {{ candidateData?.in_city ? candidateData?.in_city : "---" }},
                {{ candidateData?.in_state }}
              </li>
              <li class="col-5">
                Country
                <span
                  *ngIf="candidateData?.is_in_country_full_name_modified"
                  style="color: blue"
                  >#</span
                >
              </li>
              <li class="col-7">
                {{
                  candidateData?.in_country_full_name
                    ? candidateData?.in_country_full_name
                    : "---"
                }}
              </li>
            </ul>
            <p style="font-size: 12px">
              <span style="color: blue">#</span> This data was modified by the
              User and not refreshed from LinkedIn.
            </p>
          </div>
        </div>
      </div>
      <div class="accordion-item mb-3 rounded overflow-hidden">
        <h2 class="accordion-header" id="experiencePanelStayOpen-headingfive">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#experiencePanelStayOpen-collapsefive"
            aria-expanded="true"
            aria-controls="experiencePanelStayOpen-collapsefive"
          >
            Experience
          </button>
          <a
            href="javascript:void(0)"
            class="edit"
            title="Add"
            (click)="open(AddExperience, 'AddExperience')"
            ><img src="./assets/img/plus-black.svg" alt=""
          /></a>
        </h2>
        <div
          id="experiencePanelStayOpen-collapsefive"
          class="accordion-collapse collapse"
          aria-labelledby="experiencePanelStayOpen-headingfive"
        >
          <div class="accordion-body">
            <div
              id="experiencePanelStayOpen-collapseseven"
              class="accordion-collapse collapse show scrolllicence"
              aria-labelledby="experiencePanelStayOpen-headingseven"
            >
              <div
                class="accordion-body pl-0"
                *ngFor="let experience of experienceList"
              >
                <div class="experience d-flex justify-content-between">
                  <div class="experience-left">
                    <h4 class="m-0">{{ experience?.company }}</h4>
                    <h4 class="m-0">{{ experience?.title }}</h4>
                    <p class="m-0">{{ experience?.description }}</p>
                    <p class="m-0">
                      {{ experience?.start_date | date : "MM/dd/yyyy" }}
                      <span *ngIf="experience.start_date && experience.end_date"
                        >to</span
                      >
                      {{ experience.end_date | date : "MM/dd/yyyy" }}
                    </p>
                  </div>
                  <div class="inner-edit">
                    <a
                      href="javascript:void(0)"
                      (click)="
                        open(
                          UpdateExperienceDetails,
                          'UpdateExperienceDetails',
                          experience.id
                        )
                      "
                      ><em class="bi bi-pencil-square me-2" title="Edit"></em
                    ></a>
                    <a
                      href="javascript:void(0)"
                      (click)="
                        open(
                          deleteExperience,
                          'deleteExperience',
                          experience.id,
                          experience.name
                        )
                      "
                    >
                      <em class="bi bi-trash" title="Delete"></em
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-body" *ngIf="experienceList.length == 0">
          No Experience Added
        </div>
      </div>
    </div>

    <div class="accordion col-4" id="accordionPanelsStayOpenExample-two">
      <!-- Candidate Searches -->
      <div class="accordion-item mb-3 rounded overflow-hidden">
        <h2 class="accordion-header" id="panelsStayOpen-headingfour">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapsefour"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapsefour"
          >
            Candidate Searches & Job postings
          </button>
          <a
            href="javascript:void(0)"
            class="edit"
            title="Add candidate to search"
            (click)="open(projectList, 'projectList')"
            ><img src="./assets/img/plus-black.svg" alt=""
          /></a>
        </h2>

        <div
          id="panelsStayOpen-collapsefour"
          class="accordion-collapse collapse show scrollsearch"
          aria-labelledby="panelsStayOpen-headingfour"
        >
          <div
            class="accordion-body"
            *ngFor="let project of projectData.slice(1); let i = index"
          >
            <div class="experience d-flex justify-content-between">
              <div class="experience-left">
                <h4>
                  <a
                    (click)="
                      showProject2(
                        project.id,
                        (candidateData?.in_first_name
                          ? candidateData?.in_first_name
                          : '') +
                          ' ' +
                          (candidateData?.in_last_name
                            ? candidateData?.in_last_name
                            : ''),
                        project.rank
                      )
                    "
                    routerLink="/search"
                    title=""
                    >{{ project?.dropdown }}</a
                  >
                </h4>
                <p class="small m-0">
                  Last updated by {{ project?.updated_by }} on
                  {{ project?.updated_at | date : "MM-dd-yyyy" }}
                </p>
              </div>
              <div class="inner-edit">
                <a
                  href="javascript:void(0)"
                  (click)="
                    open(
                      deleteProject,
                      'deleteProject',
                      project.id,
                      project.name
                    )
                  "
                  ><em class="bi bi-trash" title="Delete"></em
                ></a>
              </div>
            </div>
            <div
              class="rating d-flex align-items-center justify-content-between mt-4"
            >
              <input
                name="projectRank_{{ project.id }}"
                id="projectRank_{{ project.id }}"
                (blur)="projectchange(project.id, 'projectRank')"
                type="text"
                (keypress)="restrictAlphabet($event)"
                class="form-control"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-sm"
                min="1"
                value="{{ project.rank }}"
              />

              <select
                name="projectStatus_{{ project.id }}"
                class="custom-dropdown form-select"
                [(ngModel)]="project.candidate_status_id"
                (change)="
                  projectchange(
                    project.id,
                    'projectStatus',
                    project.candidate_status_id
                  )
                "
              >
                <option value="null">Select status</option>
                <option
                  *ngFor="let status of candidateStatus"
                  id="projectStatus_{{ project.id }}"
                  [value]="status.id"
                >
                  {{ status.name }}
                </option>
              </select>

              <select
                class="custom-dropdown form-select"
                [(ngModel)]="project.strength"
                [ngStyle]="{
                  color: project.strength == '0' ? '#737373' : '#63c9c1',
                  'font-size': project.strength == '0' ? '13px' : '20px'
                }"
                style="padding: 0px; padding-left: 5px"
                (change)="
                  projectchange(
                    project.id,
                    'project_Strength',
                    project.strength
                  )
                "
              >
                <option value="0" style="font-size: 14px">
                  Select strength
                </option>
                <option class="favorite-icon" value="1">&#9733;</option>
                <option class="favorite-icon" value="2">&#9733;&#9733;</option>
                <option class="favorite-icon" value="3">
                  &#9733;&#9733;&#9733;
                </option>
                <option class="favorite-icon" value="4">
                  &#9733;&#9733;&#9733;&#9733;
                </option>
                <option class="favorite-icon" value="5">
                  &#9733;&#9733;&#9733;&#9733;&#9733;
                </option>
              </select>

              <div class="form-check form-switch p-0 m-0 ms-4">
                <input
                  class="form-check-input"
                  (change)="
                    allowExportchecked($event, project.id, 'allow_export')
                  "
                  style="cursor: pointer"
                  title="Enable export"
                  type="checkbox"
                  id="flexSwitchCheckChecked"
                  [checked]="project.allow_export"
                />
              </div>
            </div>
            <hr />
          </div>
          <div
            class="accordion-body"
            *ngIf="
              projectData?.length == 0 ||
              (projectData?.length == 1 && projectData[0].project_id == 0)
            "
          >
            No Search added
          </div>
        </div>
      </div>

      <!-- New/Expansion Business -->

      <div
        *ngIf="is_client_module_access_disabled == '0'"
        class="accordion-item mb-3 rounded overflow-hidden"
      >
        <h2 class="accordion-header" id="panelsStayOpen-headingfour">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-newExpansion"
            aria-expanded="true"
            aria-controls="panelsStayOpen-newExpansion"
          >
            New/Expansion Business
          </button>
          <a
            href="javascript:void(0)"
            title="Add"
            class="edit"
            (click)="open(AddNewBusiness, 'AddNewBusiness')"
            ><img src="./assets/img/plus-black.svg" alt=""
          /></a>
        </h2>

        <div
          id="panelsStayOpen-newExpansion"
          class="accordion-collapse collapse show scrollsearch"
          aria-labelledby="panelsStayOpen-headingfour"
        >
          <div
            class="accordion-body"
            *ngFor="let cl of clientsData; let i = index"
          >
            <!-- Name -->
            <div class="experience d-flex justify-content-between">
              <div class="experience-left">
                <h4>
                  <a
                    href="javascript:void(0);"
                    (click)="showProject3(cl?.clients.id)"
                    title="{{ cl?.clients.name }}"
                  >
                    {{ cl?.clients.name }}
                  </a>
                </h4>
              </div>
              <div class="inner-edit">
                <a
                  href="javascript:void(0)"
                  (click)="
                    open(
                      deleteclientModal,
                      'deleteclientModal',
                      cl.clients.id,
                      cl.clients.name
                    )
                  "
                  ><em class="bi bi-trash" title="Delete"></em
                ></a>
              </div>
            </div>

            <div
              class="rating d-flex align-items-center justify-content-between mt-4"
            >
              <select
                name="client_status_{{ cl.clients.client_status_id }}"
                class="custom-dropdownnew form-select"
                [(ngModel)]="cl.clients.client_status_id"
                (change)="
                  ClientStatusChange(
                    cl.clients.name,
                    'client_status',
                    cl?.clients.client_status_id
                  )
                "
              >
                <option value="0">Select Client Stage</option>
                <option
                  *ngFor="let status of ClientStatuslist"
                  id="client_status_{{ cl?.clients.id }}"
                  [value]="status.id"
                >
                  {{ status.name }}
                </option>
              </select>
              <select
                name="Probability"
                id="Probability"
                class="custom-dropdownpro form-select"
                [(ngModel)]="cl?.clients.client_probability_status_id"
                (change)="
                  Probabilitychange(
                    cl?.clients.name,
                    'client_state',
                    cl?.clients.client_probability_status_id
                  )
                "
              >
                <option value="0">Select Probability</option>
                <option
                  *ngFor="let status of ClientProbabiltylist"
                  id="client_status_{{ cl.id }}"
                  [value]="status.id"
                >
                  {{ status.name }}
                </option>
              </select>
            </div>

            <hr />
          </div>

          <div
            class="accordion-body"
            *ngIf="
              clientsData?.length == 0 ||
              (clientsData?.length == 1 && clientsData[0].id == 0)
            "
          >
            No Client Associated
          </div>
        </div>
      </div>

      <!-- End New/Expansion Business-->

      <div class="accordion-item mb-3 rounded overflow-hidden">
        <h2 class="accordion-header" id="panelsStayOpen-headingseven">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseseven"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapseseven"
          >
            Education
          </button>
          <a
            href="javascript:void(0)"
            title="Add"
            class="edit"
            (click)="open(AddEducation, 'AddEducation')"
            ><img src="./assets/img/plus-black.svg" alt=""
          /></a>
        </h2>
        <div
          id="panelsStayOpen-collapseseven"
          class="accordion-collapse collapse show scrolleducation"
          aria-labelledby="panelsStayOpen-headingseven"
        >
          <div class="accordion-body" *ngFor="let education of educationData">
            <div class="experience d-flex justify-content-between">
              <div class="experience-left">
                <h4>{{ education?.degree_name }}</h4>
                <p class="m-0">{{ education?.school }}</p>
                <p class="m-0">
                  {{ education?.start_date | date : "MM/dd/yyyy" }}
                  <span *ngIf="education.start_date && education.end_date"
                    >to</span
                  >
                  {{ education.end_date | date : "MM/dd/yyyy" }}
                </p>
              </div>
              <div class="inner-edit">
                <a
                  href="javascript:void(0)"
                  (click)="
                    open(UpdateEducation, 'UpdateEducation', education.id)
                  "
                  ><em class="bi bi-pencil-square me-2" title="Edit"></em
                ></a>
                <a
                  href="javascript:void(0)"
                  (click)="
                    open(
                      deleteEducation,
                      'deleteEducation',
                      education.id,
                      education.degree_name
                    )
                  "
                >
                  <em class="bi bi-trash" title="Delete"></em
                ></a>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-body" *ngIf="educationData?.length == 0">
          No Education added
        </div>
      </div>

      <div class="accordion-item mb-3 rounded overflow-hidden">
        <h2 class="accordion-header" id="panelsStayOpen-headingsix">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapsesix"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapsesix"
          >
            Documents
          </button>
          <a
            href="javascript:void(0)"
            title="Add"
            (click)="open(UploadDocument, 'UploadDocument')"
            class="edit"
            ><img src="./assets/img/plus-black.svg" alt=""
          /></a>
        </h2>
        <div
          id="panelsStayOpen-collapsesix"
          class="accordion-collapse collapse show scrolldocument"
          aria-labelledby="panelsStayOpen-headingsix"
        >
          <div class="accordion-body" *ngFor="let document of documentData">
            <div class="experience d-flex justify-content-between">
              <div class="experience-left col-lg-10">
                <h4 class="m-0">
                  <a href="{{ document?.path }}" target="_blank">
                    {{ document?.name }}</a
                  >
                  &nbsp;{{ document.is_resume ? "[Resume]" : "" }}
                </h4>
                <p *ngIf="document.description != 'null'" class="m-0">
                  {{ document?.description }}
                </p>
                <p *ngIf="document.description == 'null'">-</p>
              </div>
              <div class="inner-edit col-lg-2" style="text-align: right">
                <a
                  href="javascript:void(0)"
                  (click)="open(EditDocument, 'EditDocument', document.id)"
                  ><em class="bi bi-pencil-square me-2" title="Edit"></em
                ></a>
                <a
                  href="javascript:void(0)"
                  (click)="
                    open(
                      deleteDocument,
                      'deleteDocument',
                      document.id,
                      document.name
                    )
                  "
                >
                  <em class="bi bi-trash" title="Delete"></em
                ></a>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-body" *ngIf="documentData?.length == 0">
          No Documents added
        </div>
      </div>

      <div class="accordion-item mb-3 rounded overflow-hidden">
        <h2 class="accordion-header" id="panelsStayOpen-headingfive">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapsefive"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapsefive"
          >
            License & Certifications
          </button>
          <a
            href="javascript:void(0)"
            class="edit"
            title="Add"
            (click)="open(AddCertificate, 'AddCertificate')"
            ><img src="./assets/img/plus-black.svg" alt=""
          /></a>
        </h2>
        <div
          id="panelsStayOpen-collapsefive"
          class="accordion-collapse collapse"
          aria-labelledby="panelsStayOpen-headingfive"
        >
          <div class="accordion-body">
            <div
              id="panelsStayOpen-collapseseven"
              class="accordion-collapse collapse show scrolllicence"
              aria-labelledby="panelsStayOpen-headingseven"
            >
              <div
                class="accordion-body pl-0"
                *ngFor="let certificate of certificateList"
              >
                <div class="experience d-flex justify-content-between">
                  <div class="experience-left">
                    <h4 class="m-0">{{ certificate?.name }}</h4>
                    <p class="m-0">{{ certificate?.authority }}</p>
                    <p class="m-0">
                      {{ certificate?.start_date | date : "MM/dd/yyyy" }}
                      <span
                        *ngIf="certificate.start_date && certificate.end_date"
                        >to</span
                      >
                      {{ certificate.end_date | date : "MM/dd/yyyy" }}
                    </p>
                  </div>
                  <div class="inner-edit">
                    <a
                      href="javascript:void(0)"
                      (click)="
                        open(EditCertificate, 'EditCertificate', certificate.id)
                      "
                      ><em class="bi bi-pencil-square me-2" title="Edit"></em
                    ></a>
                    <a
                      href="javascript:void(0)"
                      (click)="
                        open(
                          deleteCerticate,
                          'deleteCerticate',
                          certificate.id,
                          certificate.name
                        )
                      "
                    >
                      <em class="bi bi-trash" title="Delete"></em
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-body" *ngIf="certificateList?.length == 0">
          No license and certifications added
        </div>
      </div>

      <!-- <div class="accordion-item mb-3 rounded overflow-hidden">
        <h2 class="accordion-header" id="experiencePanelStayOpen-headingfive">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#experiencePanelStayOpen-collapsefive"
            aria-expanded="true"
            aria-controls="experiencePanelStayOpen-collapsefive"
          >
            Experience
          </button>
          <a
            href="javascript:void(0)"
            class="edit"
            title="Add"
            (click)="open(AddExperience, 'AddExperience')"
            ><img src="./assets/img/plus-black.svg" alt=""
          /></a>
        </h2>
        <div
          id="experiencePanelStayOpen-collapsefive"
          class="accordion-collapse collapse"
          aria-labelledby="experiencePanelStayOpen-headingfive"
        >
          <div class="accordion-body">
            <div
              id="experiencePanelStayOpen-collapseseven"
              class="accordion-collapse collapse show scrolllicence"
              aria-labelledby="experiencePanelStayOpen-headingseven"
            >
              <div
                class="accordion-body pl-0"
                *ngFor="let experience of experienceList"
              >
                <div class="experience d-flex justify-content-between">
                  <div class="experience-left">
                    <h4 class="m-0">{{ experience?.company }}</h4>
                    <h4 class="m-0">{{ experience?.title }}</h4>
                    <p class="m-0">{{ experience?.description }}</p>
                    <p class="m-0">
                      {{ experience?.start_date | date : "MM/dd/yyyy" }}
                      <span *ngIf="experience.start_date && experience.end_date"
                        >to</span
                      >
                      {{ experience.end_date | date : "MM/dd/yyyy" }}
                    </p>
                  </div>
                  <div class="inner-edit">
                    <a
                      href="javascript:void(0)"
                      (click)="
                        open(
                          UpdateExperienceDetails,
                          'UpdateExperienceDetails',
                          experience.id
                        )
                      "
                      ><em class="bi bi-pencil-square me-2" title="Edit"></em
                    ></a>
                    <a
                      href="javascript:void(0)"
                      (click)="
                        open(
                          deleteExperience,
                          'deleteExperience',
                          experience.id,
                          experience.name
                        )
                      "
                    >
                      <em class="bi bi-trash" title="Delete"></em
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-body" *ngIf="experienceList.length == 0">
          No Experience Added
        </div>
      </div> -->
    </div>

    <div class="accordion col-4" id="accordionPanelsStayOpenExample-three">
      <div class="accordion-item mb-3 rounded overflow-hidden">
        <h2 class="accordion-header" id="panelsStayOpen-heading-three-one">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapse-three-one"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapse-three-one"
          >
            Candidate Notes
          </button>
          <a
            href="javascript:void(0)"
            class="edit"
            (click)="
              open(createNote, 'createNote', undefined, undefined, 'xl');
              activeNote = 'candidate'
            "
            ><img src="./assets/img/plus-black.svg" alt=""
          /></a>
        </h2>
        <div
          id="panelsStayOpen-collapse-three-one"
          class="accordion-collapse collapse show"
          aria-labelledby="panelsStayOpen-heading-three-one"
        >
          <div class="accordion-body scrollnotes">
            <form [formGroup]="CreateNoteForm" #formDirective="ngForm">
              <ng-select
                [items]="projectData"
                (change)="getCandidateNotes()"
                class="form-control form-select"
                placeholder="All Searches"
                bindLabel="dropdown"
                formControlName="notes_project"
                bindValue="id"
              >
              </ng-select>
            </form>

            <div
              class="border rounded py-3 px-2 mt-2"
              *ngFor="let nl of notesList"
            >
              <div class="d-flex justify-content-between mb-3">
                <div class="sub-profile d-flex align-items-center">
                  <button
                    class="profile-name"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style="padding: 0px"
                  >
                    <img
                      *ngIf="nl?.creator?.avatar_path !== null"
                      style="border-radius: 50%"
                      src="{{ nl?.creator?.avatar_path }}"
                      alt=""
                    />
                    <span
                      *ngIf="nl?.creator?.avatar_path === null"
                      style="padding-left: 0px; color: white"
                      >{{ nl.initials }}</span
                    >
                  </button>

                  <h5>
                    {{ nl.creator?.name ? nl.creator?.name : "" }} <br />
                    <p class="online m-0">
                      {{ nl?.updated_at | date : "MM-dd-yyyy" }} at
                      {{
                        nl?.updated_at
                          | date
                            : "hh:mm
                      a"
                      }}
                    </p>
                  </h5>
                </div>
                <div class="inner-edit">
                  <em
                    class="bi bi-share-fill me-2"
                    *ngIf="nl.allow_export"
                  ></em>
                  <em *ngIf="nl.ms_mail_id" class="bi bi-envelope me-2"></em>
                  <a
                    href="javascript:void(0)"
                    (click)="
                      activeNote = 'candidate';
                      open(editNote, 'editNote', nl.id, nl.project?.name, 'xl')
                    "
                    ><em class="bi bi-pencil-square me-2"></em
                  ></a>
                  <a
                    href="javascript:void(0)"
                    (click)="
                      activeNote = 'candidate';
                      open(deleteNote, 'deleteNote', nl.id, nl.project?.name)
                    "
                  >
                    <em class="bi bi-trash"></em
                  ></a>
                </div>
              </div>
              <p>
                <strong>
                  <a
                    (click)="showProject4(nl.project.id)"
                    routerLink="/search/nl.project.id"
                    title=""
                    >{{ nl.project?.name }}
                  </a>
                  <a
                    (click)="showProject4(nl.project.id)"
                    routerLink="/search"
                    title=""
                    *ngIf="
                      (nl.project?.client?.name != null ||
                        nl.project?.client?.name != '') &&
                      nl.project_id != null
                    "
                  >
                    [{{
                      nl.project?.client?.name
                        ? [nl.project?.client?.name]
                        : ""
                    }}]
                  </a>
                </strong>
              </p>

              <p *ngIf="nl.project_id == null">
                <strong style="color: #63c9c1"> General </strong>
              </p>

              <p class="m-0" style="white-space: pre-line">{{ nl?.note }}</p>
            </div>
            <div class="accordion-body" *ngIf="notesList?.length == 0">
              No notes added
            </div>
          </div>
        </div>
      </div>

      <div class="accordion-item mb-3 rounded overflow-hidden" *ngIf="( is_client_module_access_disabled == '0')">
        <h2
          class="accordion-header"
          id="panelsStayOpen-client-heading-three-one"
        >
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-client-collapse-three-one"
            aria-expanded="true"
            aria-controls="panelsStayOpen-client-collapse-three-one"
          >
            Client Notes
          </button>
          <a
            href="javascript:void(0)"
            class="edit"
            (click)="
              open(createNote, 'createNote', undefined, undefined, 'xl');
              activeNote = 'client'
            "
            ><img src="./assets/img/plus-black.svg" alt=""
          /></a>
        </h2>
        <div
          id="panelsStayOpen-client-collapse-three-one"
          class="accordion-collapse collapse show"
          aria-labelledby="panelsStayOpen-client-heading-three-one"
        >
          <div class="accordion-body scrollnotes">
            <form [formGroup]="CreateNoteForm" #formDirective="ngForm">
              <ng-select
                [items]="clientsData"
                (change)="getClientNotes()"
                class="form-control form-select"
                placeholder="All Clients"
                bindLabel="clients.name"
                formControlName="client_notes"
                bindValue="clients.id"
              >
              </ng-select>
            </form>

            <div
              class="border rounded py-3 px-2 mt-2"
              *ngFor="let nl of clientNotesList"
            >
              <div class="d-flex justify-content-between mb-3">
                <div class="sub-profile d-flex align-items-center">
                  <button
                    class="profile-name"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style="padding: 0px"
                  >
                    <img
                      *ngIf="nl?.creator?.avatar_path !== null"
                      style="border-radius: 50%"
                      src="{{ nl?.creator?.avatar_path }}"
                      alt=""
                    />
                    <span
                      *ngIf="nl?.creator?.avatar_path === null"
                      style="padding-left: 0px; color: white"
                      >{{ nl.initials }}</span
                    >
                  </button>

                  <h5>
                    {{ nl.creator?.name ? nl.creator?.name : "" }} <br />
                    <p class="online m-0">
                      {{ nl?.updated_at | date : "MM-dd-yyyy" }} at
                      {{
                        nl?.updated_at
                          | date
                            : "hh:mm
                      a"
                      }}
                    </p>
                  </h5>
                </div>
                <div class="inner-edit">
                  <em
                    class="bi bi-share-fill me-2"
                    *ngIf="nl.allow_export"
                  ></em>
                  <em *ngIf="nl.ms_mail_id" class="bi bi-envelope me-2"></em>
                  <a
                    href="javascript:void(0)"
                    (click)="
                      activeNote = 'client';
                      open(editNote, 'editNote', nl.id, nl.project?.name, 'xl')
                    "
                    ><em class="bi bi-pencil-square me-2"></em
                  ></a>
                  <a
                    href="javascript:void(0)"
                    (click)="
                      activeNote = 'client';
                      open(deleteNote, 'deleteNote', nl.id, nl.project?.name)
                    "
                  >
                    <em class="bi bi-trash"></em
                  ></a>
                </div>
              </div>
              <p>
                <strong>
                  <a
                    style="cursor: pointer"
                    (click)="showProject3(nl?.client_id)"
                    title=""
                    >{{ getClientName(nl?.client_id) }}
                  </a>
                  <!-- <a
                    (click)="showProject3(nl.project.id)"
                    routerLink="/search"
                    title=""
                    *ngIf="
                      (nl.project?.client?.name != null ||
                        nl.project?.client?.name != '') &&
                      nl.project_id != null
                    "
                  >
                    [{{
                      nl.project?.client?.name
                        ? [nl.project?.client?.name]
                        : ""
                    }}]
                  </a> -->
                </strong>
              </p>

              <p class="m-0" style="white-space: pre-line">{{ nl?.note }}</p>
            </div>
            <div class="accordion-body" *ngIf="clientNotesList.length == 0">
              No notes added
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #UpdateCandidateDetails let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Edit Contact Profile</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <form [formGroup]="CreateCandidateDetailsForm">
      <div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateCandidateDetailsError"
        >
          {{ CreateCandidateDetailsError }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateCandidateDetailsErrorFirstname"
        >
          {{ CreateCandidateDetailsErrorFirstname }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateCandidateDetailsErrorLastname"
        >
          {{ CreateCandidateDetailsErrorLastname }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateCandidateDetailsErrorGender"
        >
          {{ CreateCandidateDetailsErrorGender }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateCandidateDetailsErrorInbirthdate"
        >
          {{ CreateCandidateDetailsErrorInbirthdate }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateCandidateDetailsErrorCurrentcompany"
        >
          {{ CreateCandidateDetailsErrorCurrentcompany }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateCandidateDetailsErrorCurrentposition"
        >
          {{ CreateCandidateDetailsErrorCurrentposition }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateCandidateDetailsErrorCustomdiversity"
        >
          {{ CreateCandidateDetailsErrorCustomdiversity }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateCandidateDetailsErrorCustomcompensationdetails"
        >
          {{ CreateCandidateDetailsErrorCustomcompensationdetails }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateCandidateDetailsErrorLanguageids"
        >
          {{ CreateCandidateDetailsErrorLanguageids }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateCandidateDetailsErrorLinkedinurl"
        >
          {{ CreateCandidateDetailsErrorLinkedinurl }}
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="">First name</label>
              <span class="text-danger" style="font-size: 14px"
                >&nbsp;<strong>*</strong></span
              >
              <input
                type="text"
                placeholder="Enter first name"
                formControlName="firstname"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f['firstname'].errors }"
              />

              <div
                style="color: red; font-size: 14px"
                *ngIf="
                  submitted &&
                  f['firstname'] &&
                  f['firstname'].errors &&
                  f['firstname'].errors['required']
                "
              >
                First name is required
              </div>

              <div
                style="color: red; font-size: 14px; text-align: left"
                class="w-100 col-12 ps-0"
                *ngIf="submitted && (f['firstname'].errors?.['minlength'] || f['firstname'].errors?.['maxlength'])"
              >
                First name must be between 1 - 15 characters.
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="">Last name</label>
              <span class="text-danger" style="font-size: 14px"
                >&nbsp;<strong>*</strong></span
              >
              <input
                type="text"
                placeholder="Enter last name"
                formControlName="lastname"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f['lastname'].errors }"
              />
              <div
                style="color: red; font-size: 14px"
                *ngIf="
                  submitted &&
                  f['lastname'] &&
                  f['lastname'].errors &&
                  f['lastname'].errors['required']
                "
              >
                Last name is required
              </div>
              <div
                style="color: red; font-size: 14px; text-align: left"
                class="w-100 col-12 ps-0"
                *ngIf="submitted && (f['lastname'].errors?.['minlength'] || f['lastname'].errors?.['maxlength'])"
              >
                Last name must be between 1 - 15 characters.
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="">Strength </label>
              <select
                formControlName="strength"
                class="form-select"
                style="
                  padding: 0px;
                  padding-left: 5px;
                  border: 1px solid #dee2e6;
                  width: 100% !important;
                  height: 37.6px !important;
                "
              >
                <option value="0" style="font-size: 14px">
                  Select strength
                </option>
                <option class="favorite-icon" value="1">&#9733;</option>
                <option class="favorite-icon" value="2">&#9733;&#9733;</option>
                <option class="favorite-icon" value="3">
                  &#9733;&#9733;&#9733;
                </option>
                <option class="favorite-icon" value="4">
                  &#9733;&#9733;&#9733;&#9733;
                </option>
                <option class="favorite-icon" value="5">
                  &#9733;&#9733;&#9733;&#9733;&#9733;
                </option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for=""
                >Current Company <small>(Max 30 characters)</small></label
              >
              <input
                type="text"
                placeholder="Enter current company"
                formControlName="current_company"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for=""
                >Current Position <small>(Max 30 characters)</small></label
              >
              <input
                type="text"
                placeholder="Enter current position"
                formControlName="current_position"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="">Birth Date</label>
              <input
                type="date"
                placeholder="Enter birth date"
                formControlName="in_birth_date"
                class="form-control"
              />
              <div
                style="color: red; font-size: 14px"
                *ngIf="submitted && f['in_birth_date'].errors"
              >
                <div *ngIf="f['in_birth_date'].errors['futureDate']">
                  Birth Date cannot be in the future.
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-6">
            <div class="mb-3">
              <label for="">Gender</label>
              <select name="" id="" class="form-select" formControlName="gender">
                <option value=null>Select gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div> -->
        </div>

        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="Language">Language</label>
              <ng-select
                [items]="languageData"
                class="form-control form-select"
                [multiple]="true"
                bindLabel="name"
                bindValue="id"
                formControlName="language_ids"
                placeholder="Select language"
              >
              </ng-select>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="">Linked-In URL</label>
              <input
                type="text"
                [readonly]="
                  candidateData.creation_type !== 'Manual' &&
                  candidateData.linkedin_url !== null
                "
                placeholder="Enter Linked-In URL"
                formControlName="linkedin_url"
                class="form-control"
              />
            </div>
            <!-- <div class="mb-3">
              <label for="">Diverse Candidate</label>
              <select
                name=""
                id=""
                class="form-select"
                formControlName="custom_diversity"
              >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div> -->
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="">Source</label>
              <input
                type="text"
                readonly
                placeholder="Enter Source"
                formControlName="source"
                class="form-control"
              />
            </div>
            <!-- <div class="mb-3">
              <label for=""
                >Compensation <small>(Max 30 characters)</small></label
              >
              <input
                type="text"
                placeholder="Enter custom compensation details"
                formControlName="custom_compensation_details"
                class="form-control"
              />
            </div> -->
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="">Gender</label>
              <select
                name=""
                id=""
                class="form-select"
                formControlName="gender"
              >
                <option value="null">Select gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="">Diverse Candidate</label>
              <select
                name=""
                id=""
                class="form-select"
                formControlName="custom_diversity"
              >
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for=""
                >Compensation <small>(Max 30 characters)</small></label
              >
              <input
                type="text"
                placeholder="Enter custom compensation details"
                formControlName="custom_compensation_details"
                class="form-control"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="spinner-border" *ngIf="projectapicall">
          <output>Loading...</output>
        </div>
        <button
          type="button"
          class="filter"
          (click)="modal.close('Save click')"
          *ngIf="!projectapicall"
        >
          Cancel
        </button>
        <button
          type="button"
          class="blue-btn ms-"
          (click)="UpdateCandidatesDetails()"
          *ngIf="!projectapicall"
        >
          Save
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #UpdateContactDetails let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Edit Contact Details</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <form [formGroup]="CreateCandidateDetailsForm">
      <div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateContactsDetailsError"
        >
          {{ CreateContactsDetailsError }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateContactsDetailsErrorInpersonalphone"
        >
          {{ CreateContactsDetailsErrorInpersonalphone }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateContactsDetailsErrorInpersonalemail"
        >
          {{ CreateContactsDetailsErrorInpersonalemail }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateContactsDetailsErrorInworkphone"
        >
          {{ CreateContactsDetailsErrorInworkphone }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateContactsDetailsErrorInworkemail"
        >
          {{ CreateContactsDetailsErrorInworkemail }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateContactsDetailsErrorisInpersonalphonepreffered"
        >
          {{ CreateContactsDetailsErrorisInpersonalphonepreffered }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateContactsDetailsErrorisInpersonalemailpreffered"
        >
          {{ CreateContactsDetailsErrorisInpersonalemailpreffered }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateContactsDetailsErrorisInworkphonepreffered"
        >
          {{ CreateContactsDetailsErrorisInworkphonepreffered }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateContactsDetailsErrorisInworkemailpreffered"
        >
          {{ CreateContactsDetailsErrorisInworkemailpreffered }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateContactsDetailsErrorInstate"
        >
          {{ CreateContactsDetailsErrorInstate }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateContactsDetailsErrorIncity"
        >
          {{ CreateContactsDetailsErrorIncity }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateContactsDetailsErrorIncountryfullname"
        >
          {{ CreateContactsDetailsErrorIncountryfullname }}
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="">Personal Phone</label>
              <input
                type="text"
                placeholder="Enter personal phone"
                (keypress)="restrictAlphabet($event)"
                formControlName="in_personal_phone"
                maxlength="20"
                class="form-control"
              />
              <div class="form-check pt-1">
                <input
                  class="form-check-input"
                  title="preferred"
                  type="checkbox"
                  value=""
                  formControlName="is_in_personal_phone_preffered"
                />
                <label
                  class="form-check-label"
                  title="preferred"
                  for="flexCheckDefault"
                >
                  Preferred phone
                </label>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for=""
                >Personal Email
                <span *ngIf="candidateData.is_personal_email_read_only === 1">
                  <button
                    style="margin-left: -30px"
                    type="button"
                    class="btn btn-icon btn-sm"
                    [ngbTooltip]="tooltipContent"
                    placement="top"
                  >
                    <em class="fa fa-info-circle" aria-hidden="true"></em>
                  </button>
                </span>
              </label>

              <input
                type="text"
                placeholder="Enter personal Email"
                formControlName="in_personal_email"
                class="form-control"
              />
              <div class="form-check pt-1">
                <input
                  class="form-check-input"
                  title="preferred"
                  type="checkbox"
                  value=""
                  formControlName="is_in_personal_email_preffered"
                />
                <label
                  class="form-check-label"
                  title="preferred"
                  for="flexCheckDefault"
                >
                  Preferred email
                </label>
                <span
                  style="color: red; font-size: 14px; text-align: left"
                  class="w-100 col-12 ps-0"
                  *ngIf="
                    f['in_personal_email'] &&
                    f['in_personal_email'].errors &&
                    f['in_personal_email'].errors['pattern']
                  "
                >
                  Invalid Email Address
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="">Work phone</label>
              <input
                type="text"
                placeholder="Enter work phone"
                (keypress)="restrictAlphabet($event)"
                formControlName="in_work_phone"
                maxlength="20"
                class="form-control"
              />
              <div class="form-check pt-1">
                <input
                  class="form-check-input"
                  title="preferred"
                  type="checkbox"
                  value=""
                  formControlName="is_in_work_phone_preffered"
                />
                <label
                  class="form-check-label"
                  title="preferred"
                  for="flexCheckDefault"
                >
                  Preferred phone
                </label>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="">Work Email</label>
              <input
                type="text"
                placeholder="Enter work email"
                formControlName="in_work_email"
                class="form-control"
              />
              <div class="form-check pt-1">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  title="preferred"
                  formControlName="is_in_work_email_preffered"
                />
                <label
                  class="form-check-label"
                  for="flexCheckDefault"
                  title="preferred"
                >
                  Preferred email
                </label>
                <span
                  style="color: red; font-size: 14px; text-align: left"
                  class="w-100 col-12 ps-0"
                  *ngIf="
                    f['in_work_email'] &&
                    f['in_work_email'].errors &&
                    f['in_work_email'].errors['pattern']
                  "
                >
                  Invalid Email Address
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="">City</label>
              <input
                type="text"
                placeholder="Enter city"
                formControlName="in_city"
                class="form-control"
              />
            </div>
          </div>

          <div class="col-6">
            <div class="mb-3">
              <label for="">State</label>
              <ng-select
                [items]="stateData"
                class="form-control form-select"
                placeholder="Select state"
                bindLabel="name"
                formControlName="in_state"
                bindValue="name"
              >
              </ng-select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="">Country</label>
              <input
                type="text"
                placeholder="Enter country"
                formControlName="country"
                class="form-control"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="spinner-border" *ngIf="projectapicall">
          <span class="visually-hidden">Loading...</span>
        </div>
        <button
          type="button"
          class="filter"
          (click)="modal.close('Save click')"
          *ngIf="!projectapicall"
        >
          Cancel
        </button>
        <button
          type="button"
          class="blue-btn ms-"
          (click)="UpdateContactsDetails()"
          *ngIf="!projectapicall"
        >
          Save
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #AddExperience let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add Experience Details</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <form [formGroup]="AddExperienceForm">
      <div class="modal-body">
        <div class="mb-3">
          <label for="">Title</label>
          <span class="text-danger" style="font-size: 14px"
            >&nbsp;<strong>*</strong></span
          >
          <input
            type="text"
            placeholder="Enter title"
            formControlName="title"
            class="form-control"
            [ngClass]="{
              'is-invalid': submitted && addExpDetails['title'].errors
            }"
          />
          <div
            style="color: red; font-size: 14px"
            *ngIf="
              submitted &&
              addExpDetails['title'] &&
              addExpDetails['title'].errors &&
              addExpDetails['title'].errors['required']
            "
          >
            Title is required.
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="">Company</label>
              <span class="text-danger" style="font-size: 14px"
                >&nbsp;<strong>*</strong></span
              >
              <input
                type="text"
                placeholder="Enter company name"
                formControlName="company"
                class="form-control"
                [ngClass]="{
                  'is-invalid': submitted && addExpDetails['company'].errors
                }"
              />
              <div
                style="color: red; font-size: 14px"
                *ngIf="
                  submitted &&
                  addExpDetails['company'] &&
                  addExpDetails['company'].errors &&
                  addExpDetails['company'].errors['required']
                "
              >
                Company name is required.
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="">location</label>
              <span class="text-danger" style="font-size: 14px"
                >&nbsp;<strong>*</strong></span
              >
              <input
                type="text"
                placeholder="Enter location"
                formControlName="location"
                class="form-control"
                [ngClass]="{
                  'is-invalid': submitted && addExpDetails['location'].errors
                }"
              />
              <div
                style="color: red; font-size: 14px"
                *ngIf="
                  submitted &&
                  addExpDetails['location'] &&
                  addExpDetails['location'].errors &&
                  addExpDetails['location'].errors['required']
                "
              >
                location is required.
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-6">
            <label for="">Start Date</label>
            <span class="text-danger" style="font-size: 14px"
              >&nbsp;<strong>*</strong></span
            >
            <input
              type="date"
              formControlName="start_date"
              class="form-control"
              [ngClass]="{
                'is-invalid': submitted && addExpDetails['start_date'].errors
              }"
            />
            <div
              style="color: red; font-size: 14px"
              *ngIf="
                submitted &&
                addExpDetails['start_date'] &&
                addExpDetails['start_date'].errors &&
                addExpDetails['start_date'].errors['required']
              "
            >
              Start date is required.
            </div>
          </div>
          <div class="col-6">
            <label for="">End Date</label>
            <input
              type="date"
              formControlName="end_date"
              class="form-control"
            />
          </div>
        </div>

        <div class="mb-3">
          <label for="">Description</label>
          <textarea
            type="text"
            placeholder="Enter description"
            formControlName="description"
            class="form-control"
            rows="2"
          ></textarea>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="filter"
          (click)="modal.close('Save click')"
        >
          Cancel
        </button>
        <button
          type="button"
          class="blue-btn ms-2"
          (click)="AddExperienceDetails()"
        >
          Save
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #UpdateExperienceDetails let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Edit Experience Details
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <form [formGroup]="AddExperienceForm">
      <div class="modal-body">
        <div class="mb-3">
          <label for="">Title</label>
          <span class="text-danger" style="font-size: 14px"
            >&nbsp;<strong>*</strong></span
          >
          <input
            type="text"
            placeholder="Enter title"
            formControlName="title"
            class="form-control"
            [ngClass]="{
              'is-invalid': submitted && addExpDetails['title'].errors
            }"
          />
          <div
            style="color: red; font-size: 14px"
            *ngIf="
              submitted &&
              addExpDetails['title'] &&
              addExpDetails['title'].errors &&
              addExpDetails['title'].errors['required']
            "
          >
            Title is required.
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="">Company</label>
              <span class="text-danger" style="font-size: 14px"
                >&nbsp;<strong>*</strong></span
              >
              <input
                type="text"
                placeholder="Enter company name"
                formControlName="company"
                class="form-control"
                [ngClass]="{
                  'is-invalid': submitted && addExpDetails['company'].errors
                }"
              />
              <div
                style="color: red; font-size: 14px"
                *ngIf="
                  submitted &&
                  addExpDetails['company'] &&
                  addExpDetails['company'].errors &&
                  addExpDetails['company'].errors['required']
                "
              >
                Company name is required.
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="location">location</label>
              <span class="text-danger" style="font-size: 14px"
                >&nbsp;<strong>*</strong></span
              >
              <input
                type="text"
                placeholder="Enter location"
                formControlName="location"
                class="form-control"
                [ngClass]="{
                  'is-invalid': submitted && addExpDetails['location'].errors
                }"
              />
              <div
                style="color: red; font-size: 14px"
                *ngIf="
                  submitted &&
                  addExpDetails['location'] &&
                  addExpDetails['location'].errors &&
                  addExpDetails['location'].errors['required']
                "
              >
                Location is required.
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-6">
            <label for="">Start Date</label>
            <span class="text-danger" style="font-size: 14px"
              >&nbsp;<strong>*</strong></span
            >
            <input
              type="date"
              formControlName="start_date"
              class="form-control"
              [ngClass]="{
                'is-invalid': submitted && addExpDetails['start_date'].errors
              }"
            />
            <div
              style="color: red; font-size: 14px"
              *ngIf="
                submitted &&
                addExpDetails['start_date'] &&
                addExpDetails['start_date'].errors &&
                addExpDetails['start_date'].errors['required']
              "
            >
              Start date is required.
            </div>
          </div>
          <div class="col-6">
            <label for="">End Date</label>
            <input
              type="date"
              formControlName="end_date"
              class="form-control"
            />
          </div>
        </div>

        <div class="mb-3">
          <label for="">Description</label>
          <textarea
            type="text"
            placeholder="Enter description"
            formControlName="description"
            class="form-control"
            rows="2"
          ></textarea>
        </div>
      </div>

      <div class="modal-footer">
        <button
          type="button"
          class="filter"
          (click)="modal.close('Save click')"
        >
          Cancel
        </button>
        <button
          type="button"
          class="blue-btn ms-2"
          (click)="UpdateExperienceDetail()"
        >
          Save
        </button>
      </div>
    </form>
  </ng-template>

  <!-- Add Search  -->
  <ng-template #projectList let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Add Search or Job Posting
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click'); resetBackendAttacherror()"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="createAttachError"
        >
          {{ createAttachError }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="createAttachErrorProjectid"
        >
          {{ createAttachErrorProjectid }}
        </div>
      </div>
      <div class="mb-3">
        <label for="">Search</label>
        <span class="text-danger" style="font-size: 14px"
          >&nbsp;<strong>*</strong></span
        >
        <ng-select
          [items]="projectLists"
          class="form-control form-select"
          placeholder="Select search"
          bindLabel="dropdown"
          [(ngModel)]="selectedProject"
          bindValue="id"
          (search)="onProjectSearch($event)"
        >
        </ng-select>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="filter"
        (click)="modal.close('Save click'); resetBackendAttacherror()"
      >
        Cancel
      </button>
      <button type="button" class="blue-btn ms-2" (click)="projectSelected()">
        Save
      </button>
    </div>
  </ng-template>

  <ng-template #createClient let-modal>
    <div
      style="
        background-color: #63c9c1;
        border: 2px solid #63c9c1;
        height: 53.6px;
        display: flex;
        align-items: center;
      "
      class="modal-header"
    >
      <h4
        style="color: white; font-size: 18px; font-weight: 500"
        class="modal-title"
        id="modal-basic-title"
      >
        Create Client - {{ candidateData?.current_company }}
      </h4>
      <button
        style="color: white; font-size: 28px"
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click'); resetBackendAttacherror()"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div
      class="modal-body"
      style="border: 2px solid #63c9c1; border-bottom: none"
    >
      <div>
        <p>
          Create <strong>'{{ candidateData?.current_company }}'</strong> company
          as client and add
          <strong
            >{{ candidateData?.in_first_name }}
            {{ candidateData?.in_last_name }}' </strong
          >as the contact of '{{ candidateData?.current_company }}'.
        </p>
        <!-- <p style="display: flex;gap: 10px;" class="small mt-4 mb-0">
          <input type="checkbox" name="addAllCandidates" id="addAllCandidates">
          <label class="m-0" for="addAllCandidates">
            Add all candidates of '{{candidateData?.current_company}}' as contacts also.
          </label>
        </p> -->
      </div>
    </div>
    <div
      style="
        border-left: 2px solid #63c9c1;
        border-right: 2px solid #63c9c1;
        border-bottom: 2px solid #63c9c1;
      "
      class="modal-footer"
    >
      <button
        type="button"
        class="filter"
        (click)="modal.close('Save click'); resetBackendAttacherror()"
      >
        Cancel
      </button>
      <button
        type="button"
        class="blue-btn ms-2"
        (click)="createCurrentCompanyAsClient()"
      >
        Save
      </button>
    </div>
  </ng-template>

  <ng-template #Addtag let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add Tags</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <label for="">Tags</label>
        <span class="text-danger" style="font-size: 14px"
          >&nbsp;<strong>*</strong></span
        >
        <ng-select
          #tagselect
          [items]="tagList"
          class="form-control form-select"
          placeholder="Select Tags"
          bindLabel="name"
          [(ngModel)]="selectedtagValue"
          bindValue="id"
          (search)="onTagSearch($event)"
        >
        </ng-select>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="filter" (click)="modal.close('Save click')">
        Cancel
      </button>
      <button type="button" class="blue-btn ms-2" (click)="TagsSelected()">
        Save
      </button>
    </div>
  </ng-template>

  <ng-template #AddCertificate let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Add Certificate Details
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="
          modal.dismiss('Cross click');
          this.submitted = false;
          resetBackendAddCertificateDetailserror()
        "
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <form [formGroup]="AddCertificateForm">
      <div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateCertificateDetailsError"
        >
          {{ CreateCertificateDetailsError }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateCertificateDetailsErrorName"
        >
          {{ CreateCertificateDetailsErrorName }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateCertificateDetailsErrorAuthority"
        >
          {{ CreateCertificateDetailsErrorAuthority }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateCertificateDetailsErrorStartdate"
        >
          {{ CreateCertificateDetailsErrorStartdate }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateCertificateDetailsErrorEnddate"
        >
          {{ CreateCertificateDetailsErrorEnddate }}
        </div>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label for="">Name</label>
          <span class="text-danger" style="font-size: 14px"
            >&nbsp;<strong>*</strong></span
          >
          <input
            type="text"
            placeholder="Enter certificate name"
            formControlName="name"
            class="form-control"
            [ngClass]="{
              'is-invalid': submitted && certificateDetails['name'].errors
            }"
          />
          <div
            style="color: red; font-size: 14px"
            *ngIf="
              submitted &&
              certificateDetails['name'] &&
              certificateDetails['name'].errors &&
              certificateDetails['name'].errors['required']
            "
          >
            Name is required.
          </div>
        </div>

        <div class="mb-3">
          <label for="">Authority</label>
          <span class="text-danger" style="font-size: 14px"
            >&nbsp;<strong>*</strong></span
          >
          <input
            type="text"
            placeholder="Enter authority"
            formControlName="authority"
            class="form-control"
            [ngClass]="{
              'is-invalid': submitted && certificateDetails['authority'].errors
            }"
          />
          <div
            style="color: red; font-size: 14px"
            *ngIf="
              submitted &&
              certificateDetails['authority'] &&
              certificateDetails['authority'].errors &&
              certificateDetails['authority'].errors['required']
            "
          >
            Authority is required.
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-6">
            <label for="">Start Date</label>
            <span class="text-danger" style="font-size: 14px"
              >&nbsp;<strong>*</strong></span
            >
            <input
              type="date"
              formControlName="start_date"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  submitted && AddCertificateForm.controls['start_date'].errors
              }"
            />
            <div
              style="color: red; font-size: 14px"
              *ngIf="submitted && AddCertificateForm.controls['start_date'].errors?.['futureDate']"
              class="invalid-feedback"
            >
              Start date cannot be a future date.
            </div>
          </div>

          <div class="col-6">
            <label for="">End Date</label>
            <input
              type="date"
              formControlName="end_date"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  submitted && AddCertificateForm.controls['end_date'].errors
              }"
            />
            <div
              style="color: red; font-size: 14px"
              *ngIf="submitted && AddCertificateForm.controls['end_date'].errors?.['endDateLessThanStartDate']"
              class="invalid-feedback"
            >
              End date cannot be less than start date.
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="spinner-border" *ngIf="projectapicall">
          <span class="visually-hidden">Loading...</span>
        </div>
        <button
          type="button"
          class="filter"
          (click)="
            modal.close('Save click');
            this.submitted = false;
            resetBackendAddCertificateDetailserror()
          "
          *ngIf="!projectapicall"
        >
          Cancel
        </button>
        <button
          type="button"
          class="blue-btn ms-"
          (click)="AddCertificateDetails()"
          *ngIf="!projectapicall"
        >
          Save
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #EditCertificate let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Edit Certificate Details
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click'); this.submitted = false"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <form [formGroup]="AddCertificateForm">
      <div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="UpdateCertificateDetailsError"
        >
          {{ UpdateCertificateDetailsError }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="UpdateCertificateDetailsErrorName"
        >
          {{ UpdateCertificateDetailsErrorName }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="UpdateCertificateDetailsErrorAuthority"
        >
          {{ UpdateCertificateDetailsErrorAuthority }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="UpdateCertificateDetailsErrorStartdate"
        >
          {{ UpdateCertificateDetailsErrorStartdate }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="UpdateCertificateDetailsErrorEnddate"
        >
          {{ UpdateCertificateDetailsErrorEnddate }}
        </div>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label for="">Name</label>
          <span class="text-danger" style="font-size: 14px"
            >&nbsp;<strong>*</strong></span
          >
          <input
            type="text"
            placeholder="Enter certificate name"
            formControlName="name"
            class="form-control"
            [ngClass]="{
              'is-invalid': submitted && certificateDetails['name'].errors
            }"
          />
          <div
            style="color: red; font-size: 14px"
            *ngIf="
              submitted &&
              certificateDetails['name'] &&
              certificateDetails['name'].errors &&
              certificateDetails['name'].errors['required']
            "
          >
            Name is required.
          </div>
        </div>

        <div class="mb-3">
          <label for="">Authority</label>
          <span class="text-danger" style="font-size: 14px"
            >&nbsp;<strong>*</strong></span
          >
          <input
            type="text"
            placeholder="Enter authority"
            formControlName="authority"
            class="form-control"
            [ngClass]="{
              'is-invalid': submitted && certificateDetails['authority'].errors
            }"
          />
          <div
            style="color: red; font-size: 14px"
            *ngIf="
              submitted &&
              certificateDetails['authority'] &&
              certificateDetails['authority'].errors &&
              certificateDetails['authority'].errors['required']
            "
          >
            Authority is required.
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-6">
            <label for="">Start Date</label>
            <span class="text-danger" style="font-size: 14px"
              >&nbsp;<strong>*</strong></span
            >
            <input
              type="date"
              formControlName="start_date"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  submitted && AddCertificateForm.controls['start_date'].errors
              }"
            />
            <div
              style="color: red; font-size: 14px"
              *ngIf="submitted && AddCertificateForm.controls['start_date'].errors?.['futureDate']"
              class="invalid-feedback"
            >
              Start date cannot be a future date.
            </div>
          </div>

          <div class="col-6">
            <label for="">End Date</label>
            <input
              type="date"
              formControlName="end_date"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  submitted && AddCertificateForm.controls['end_date'].errors
              }"
            />
            <div
              style="color: red; font-size: 14px"
              *ngIf="submitted && AddCertificateForm.controls['end_date'].errors?.['endDateLessThanStartDate']"
              class="invalid-feedback"
            >
              End date cannot be less than start date.
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="spinner-border" *ngIf="projectapicall">
          <span class="visually-hidden">Loading...</span>
        </div>
        <button
          type="button"
          class="filter"
          (click)="modal.close('Save click'); this.submitted = false"
          *ngIf="!projectapicall"
        >
          Cancel
        </button>
        <button
          type="button"
          class="blue-btn ms-"
          (click)="updateCertificateDetails()"
          *ngIf="!projectapicall"
        >
          Save
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #deleteCerticate let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Delete Certificate Details
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <label for=""
          >Do you want to Delete <strong>{{ deleteName }}</strong> Certificate
          ?</label
        >
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary cancelbutton"
        (click)="modal.close('Save click')"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-danger dangerbtn"
        (click)="deleteCertificateDetail()"
      >
        Delete
      </button>
    </div>
  </ng-template>

  <ng-template #deleteExperience let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Delete Experience Details
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <label for=""
          >Do you want to Delete <strong>{{ deleteName }}</strong> Experience
          ?</label
        >
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary cancelbutton"
        (click)="modal.close('Save click')"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-danger dangerbtn"
        (click)="deleteExperienceDetail()"
      >
        Delete
      </button>
    </div>
  </ng-template>

  <ng-template #deleteNote let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Delete Note</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <label for="">Do you want to delete note ?</label>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary cancelbutton"
        (click)="modal.close('Save click')"
      >
        Cancel
      </button>
      <button
        *ngIf="activeNote !== 'client'"
        type="button"
        class="btn btn-danger dangerbtn"
        (click)="deleteNoteDetail()"
      >
        Delete
      </button>
      <button
        *ngIf="activeNote === 'client'"
        type="button"
        class="btn btn-danger dangerbtn"
        (click)="deleteNoteDetail()"
      >
        Delete
      </button>
    </div>
  </ng-template>

  <ng-template #deleteEducation let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Delete Education Details
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <label for=""
          >Do you want to Delete <strong>{{ deleteName }}</strong> Education
          ?</label
        >
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary cancelbutton"
        (click)="modal.close('Save click')"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-danger dangerbtn"
        (click)="deleteEducationDetail()"
      >
        Delete
      </button>
    </div>
  </ng-template>

  <ng-template #deleteProject let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Remove Search Details</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <label for="">Do you want to Remove Candidate from this Search?</label>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary cancelbutton"
        (click)="modal.close('Save click')"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-danger dangerbtn"
        (click)="deleteProjects()"
      >
        Delete
      </button>
    </div>
  </ng-template>

  <ng-template #AddEducation let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Add Education Details</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="
          modal.dismiss('Cross click');
          this.submitted = false;
          resetBackendAddEducationDetailserror()
        "
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <form [formGroup]="AddEducationForm">
      <div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateEducationDetailsError"
        >
          {{ CreateEducationDetailsError }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateEducationDetailsErrorDegreename"
        >
          {{ CreateEducationDetailsErrorDegreename }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateEducationDetailsErrorSchool"
        >
          {{ CreateEducationDetailsErrorSchool }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateEducationDetailsErrorStartdate"
        >
          {{ CreateEducationDetailsErrorStartdate }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreateEducationDetailsErrorEnddate"
        >
          {{ CreateEducationDetailsErrorEnddate }}
        </div>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label for="">Degree name</label>
          <span class="text-danger" style="font-size: 14px"
            >&nbsp;<strong>*</strong></span
          >
          <input
            type="text"
            placeholder="Degree is required"
            formControlName="degree_name"
            class="form-control"
            [ngClass]="{
              'is-invalid': submitted && educationDetails['degree_name'].errors
            }"
          />
          <div
            style="color: red; font-size: 14px"
            *ngIf="
              submitted &&
              educationDetails['degree_name'] &&
              educationDetails['degree_name'].errors &&
              educationDetails['degree_name'].errors['required']
            "
          >
            Degree is required.
          </div>
        </div>
        <div class="mb-3">
          <label for="">School</label>
          <input
            type="text"
            placeholder="Enter school name"
            formControlName="school"
            class="form-control"
          />
        </div>

        <div class="row mb-3">
          <div class="col-6">
            <label for="">Start Date</label>
            <input
              type="date"
              formControlName="start_date"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  submitted && AddEducationForm.controls['start_date'].errors
              }"
            />
            <div
              style="color: red; font-size: 14px"
              *ngIf="submitted && AddEducationForm.controls['start_date'].errors?.['futureDate']"
              class="invalid-feedback"
            >
              Start date cannot be a future date.
            </div>
          </div>

          <div class="col-6">
            <label for="">End Date</label>
            <input
              type="date"
              formControlName="end_date"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  submitted && AddEducationForm.controls['end_date'].errors
              }"
            />
            <div
              style="color: red; font-size: 14px"
              *ngIf="submitted && AddEducationForm.controls['end_date'].errors?.['endDateLessThanStartDate']"
              class="invalid-feedback"
            >
              End date cannot be less than start date.
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="spinner-border" *ngIf="projectapicall">
          <span class="visually-hidden">Loading...</span>
        </div>
        <button
          type="button"
          class="filter"
          (click)="
            modal.close('Save click');
            this.submitted = false;
            resetBackendAddEducationDetailserror()
          "
          *ngIf="!projectapicall"
        >
          Cancel
        </button>
        <button
          type="button"
          class="blue-btn ms-"
          (click)="AddEducationDetails()"
          *ngIf="!projectapicall"
        >
          Save
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #UpdateEducation let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Edit Education Details</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <form [formGroup]="AddEducationForm">
      <div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="UpdateEducationDetailsError"
        >
          {{ UpdateEducationDetailsError }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="UpdateEducationDetailsErrorDegreename"
        >
          {{ UpdateEducationDetailsErrorDegreename }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="UpdateEducationDetailsErrorSchool"
        >
          {{ UpdateEducationDetailsErrorSchool }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="UpdateEducationDetailsErrorStartdate"
        >
          {{ UpdateEducationDetailsErrorStartdate }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="UpdateEducationDetailsErrorEnddate"
        >
          {{ UpdateEducationDetailsErrorEnddate }}
        </div>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label for="">Degree name</label>
          <span class="text-danger" style="font-size: 14px"
            >&nbsp;<strong>*</strong></span
          >
          <input
            type="text"
            placeholder="Degree is required"
            formControlName="degree_name"
            class="form-control"
            [ngClass]="{
              'is-invalid': submitted && educationDetails['degree_name'].errors
            }"
          />
          <div
            style="color: red; font-size: 14px"
            *ngIf="
              submitted &&
              educationDetails['degree_name'] &&
              educationDetails['degree_name'].errors &&
              educationDetails['degree_name'].errors['required']
            "
          >
            Degree is required.
          </div>
        </div>
        <div class="mb-3">
          <label for="">School</label>
          <input
            type="text"
            placeholder="Enter school name"
            formControlName="school"
            class="form-control"
          />
        </div>

        <div class="row mb-3">
          <div class="col-6">
            <label for="">Start Date</label>
            <input
              type="date"
              formControlName="start_date"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  submitted && AddEducationForm.controls['start_date'].errors
              }"
            />
            <div
              style="color: red; font-size: 14px"
              *ngIf="submitted && AddEducationForm.controls['start_date'].errors?.['futureDate']"
              class="invalid-feedback"
            >
              Start date cannot be a future date.
            </div>
          </div>

          <div class="col-6">
            <label for="">End Date</label>
            <input
              type="date"
              formControlName="end_date"
              class="form-control"
              [ngClass]="{
                'is-invalid':
                  submitted && AddEducationForm.controls['end_date'].errors
              }"
            />
            <div
              style="color: red; font-size: 14px"
              *ngIf="submitted && AddEducationForm.controls['end_date'].errors?.['endDateLessThanStartDate']"
              class="invalid-feedback"
            >
              End date cannot be less than start date.
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="spinner-border" *ngIf="projectapicall">
          <span class="visually-hidden">Loading...</span>
        </div>
        <button
          type="button"
          class="filter"
          (click)="modal.close('Save click')"
          *ngIf="!projectapicall"
        >
          Cancel
        </button>
        <button
          type="button"
          class="blue-btn ms-"
          (click)="UpdateEducationDetails()"
          *ngIf="!projectapicall"
        >
          Save
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #UploadDocument let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Upload Document</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="
          modal.dismiss('Cross click');
          this.submitted = false;
          resetBackendUploadDocumenterror()
        "
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <form [formGroup]="AddDocumentForm">
      <div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreatDocumentsError"
        >
          {{ CreatDocumentsError }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreatDocumentsErrorDocument"
        >
          {{ CreatDocumentsErrorDocument }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreatDocumentsErrorDescription"
        >
          {{ CreatDocumentsErrorDescription }}
        </div>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label for="">Document</label>
          <span class="text-danger" style="font-size: 14px"
            >&nbsp;<strong>*</strong></span
          >
          <input
            type="file"
            multiple
            accept=".pdf,.doc,.docx"
            placeholder="Degree is required"
            max-file-size="10000000"
            formControlName="document"
            class="form-control"
            (change)="attachFiles($event)"
            [ngClass]="{
              'is-invalid': submitted && documentDetails['document'].errors
            }"
          />
          <div
            style="color: red; font-size: 14px"
            *ngIf="
              submitted &&
              documentDetails['document'] &&
              documentDetails['document'].errors &&
              documentDetails['document'].errors['required']
            "
          >
            Document is required.
          </div>
        </div>

        <div class="mb-3">
          <label for="">Description</label>
          <input
            type="text"
            placeholder="Enter description"
            formControlName="description"
            class="form-control"
            [ngClass]="{
              'is-invalid': submitted && documentDetails['description'].errors
            }"
          />

          <div
            style="color: red; font-size: 14px; text-align: left"
            class="w-100 col-12 ps-0"
            *ngIf="submitted && (documentDetails['description'].errors?.['minlength'] || documentDetails['description'].errors?.['maxlength'])"
          >
            Description must be between 1 - 15 characters.
          </div>
          <small class="text-primary">Max 15 characters.</small>
        </div>

        <!-- <div class="mb-3">
          <label for="is_resume">Is resume?</label>
          <ng-select
            id="is_resume"
            [clearable]="false"
            [items]="isResumeOptions"
            class="form-control form-select"
            bindLabel="label"
            bindValue="value"
            formControlName="is_resume"
            placeholder="Y or N"
          >
          </ng-select>
        </div> -->
      </div>

      <div class="modal-footer">
        <div class="spinner-border" *ngIf="projectapicall">
          <span class="visually-hidden">Loading...</span>
        </div>
        <button
          type="button"
          class="filter"
          (click)="
            modal.close('Save click');
            this.submitted = false;
            resetBackendUploadDocumenterror()
          "
          *ngIf="!projectapicall"
        >
          Cancel
        </button>
        <button
          type="button"
          class="blue-btn ms-"
          (click)="UploadDocuments()"
          *ngIf="!projectapicall"
        >
          Save
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #createNote let-modal>
    <div class="modal-header">
      <h4 class="modal-title flex-grow-1" id="modal-basic-title">
        {{
          activeNote === "client" ? "Add Client Notes" : "Add Candidate Notes"
        }}
      </h4>

      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="
          modal.dismiss('Cross click');
          this.submitted = false;
          resetBackendAddNoteerror()
        "
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <form [formGroup]="CreateNoteForm" #formDirective="ngForm">
      <div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreatNotesError"
        >
          {{ CreatNotesError }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreatNotesErrorProjectid"
        >
          {{ CreatNotesErrorProjectid }}
        </div>
        <!-- <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreatNotesErrorNote"
        >
          {{ CreatNotesErrorNote }}
        </div> -->
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="CreatNotesErrorAllowexport"
        >
          {{ CreatNotesErrorAllowexport }}
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="">
                {{
                  activeNote === "client" ? "Select Client" : "Select Search"
                }}
              </label>
              <span class="text-danger" style="font-size: 14px"
                >&nbsp;<strong>*</strong></span
              >
              <ng-select
                *ngIf="activeNote === 'client'"
                [items]="clientsData"
                (change)="getClientNotes(); clientNoteError = ''"
                class="form-control form-select"
                placeholder="Select Client"
                bindLabel="clients.name"
                formControlName="client_notes"
                bindValue="clients.id"
              >
              </ng-select>
              <ng-select
                *ngIf="activeNote === 'candidate'"
                [items]="projectData"
                (change)="getCandidateNotes()"
                class="form-control form-select"
                placeholder="All Searches"
                bindLabel="dropdown"
                formControlName="notes_project"
                bindValue="id"
                [ngClass]="{
                  'is-invalid':
                    submitted && createNoteForm['notes_project'].errors
                }"
              >
              </ng-select>
              <span
                style="color: red; font-size: 14px; text-align: left"
                class="w-100 col-12 ps-0"
                *ngIf="
                  submitted &&
                  createNoteForm['notes_project'] &&
                  createNoteForm['notes_project'].errors &&
                  createNoteForm['notes_project'].errors['required']
                "
              >
                Search is required
              </span>
              <span
                *ngIf="activeNote === 'client' && clientNoteError"
                style="color: red; font-size: 14px"
              >
                {{ clientNoteError }}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="">Notes</label>
              <span class="text-danger" style="font-size: 14px"
                >&nbsp;<strong>*</strong></span
              >
              <textarea
                class="form-control"
                formControlName="notes"
                placeholder="Start typing new note"
                aria-label="Start typing new note"
                aria-describedby="button-add"
                [ngClass]="{
                  'is-invalid':
                    submitted && createNoteForm['notes_project'].errors
                }"
                rows="8"
              >
              </textarea>
              <span
                style="color: red; font-size: 14px; text-align: left"
                class="w-100 col-12 ps-0"
                *ngIf="
                  submitted &&
                  createNoteForm['notes'] &&
                  createNoteForm['notes'].errors &&
                  createNoteForm['notes'].errors['required']
                "
              >
                Note is required
              </span>
            </div>
          </div>
        </div>
        <div *ngIf="activeNote !== 'client'" class="row">
          <div class="col-12">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                formControlName="allow_export"
              />
              <label class="form-check-label" for="allow_export">
                &nbsp;Export
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="spinner-border" *ngIf="projectapicall">
          <span class="visually-hidden">Loading...</span>
        </div>
        <button
          type="button"
          class="filter"
          (click)="
            modal.close('Save click');
            this.submitted = false;
            resetBackendAddNoteerror()
          "
          *ngIf="!projectapicall"
        >
          Cancel
        </button>
        <button
          *ngIf="!projectapicall && activeNote === 'client'"
          type="button"
          class="blue-btn ms-"
          (click)="addClientNote()"
        >
          Save
        </button>
        <button
          *ngIf="!projectapicall && activeNote !== 'client'"
          type="button"
          class="blue-btn ms-"
          (click)="addNote()"
        >
          Save
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #editNote let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Edit Notes for
        {{
          (candidateData?.in_first_name ? candidateData?.in_first_name : "") +
            " " +
            (candidateData?.in_last_name ? candidateData?.in_last_name : "")
        }}
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <form [formGroup]="UpdateNoteForm">
      <div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="UpdateNotesError"
        >
          {{ UpdateNotesError }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="UpdateNotesErrorNote"
        >
          {{ UpdateNotesErrorNote }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="UpdateNotesErrorAllowexport"
        >
          {{ UpdateNotesErrorAllowexport }}
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="">Notes</label>
              <span class="text-danger" style="font-size: 14px"
                >&nbsp;<strong>*</strong></span
              >
              <textarea
                class="form-control"
                formControlName="notes"
                placeholder="Start typing new note"
                rows="8"
                aria-label="Start typing new note"
                aria-describedby="button-add"
                [ngClass]="{
                  'is-invalid': submitted && updateNoteForm['notes'].errors
                }"
              >
              </textarea>
              <span
                style="color: red; font-size: 14px; text-align: left"
                class="w-100 col-12 ps-0"
                *ngIf="
                  submitted &&
                  updateNoteForm['notes'] &&
                  updateNoteForm['notes'].errors &&
                  updateNoteForm['notes'].errors['required']
                "
              >
                Note is required
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div *ngIf="activeNote !== 'client'" class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                formControlName="allow_export"
              />
              <label class="form-check-label" for="allow_export">
                &nbsp;Export
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            style="padding: 10px; font-size: 14px; padding-left: 20px"
            class="text-success"
            *ngIf="isEmailNotes"
          >
            Notes taken from email
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="spinner-border" *ngIf="projectapicall">
          <span class="visually-hidden">Loading...</span>
        </div>
        <input type="hidden" formControlName="notes_id" />
        <button
          type="button"
          class="filter"
          (click)="modal.close('Save click')"
          *ngIf="!projectapicall"
        >
          Cancel
        </button>
        <button
          *ngIf="!projectapicall && activeNote !== 'client'"
          type="button"
          class="blue-btn ms-"
          (click)="updateNoteDetail()"
        >
          Save
        </button>
        <button
          *ngIf="!projectapicall && activeNote === 'client'"
          type="button"
          class="blue-btn ms-"
          (click)="updateClientNoteDetail()"
        >
          Save
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #EditDocument let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Edit Document</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click'); this.submitted = false"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <form [formGroup]="AddDocumentForm">
      <div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="UpdateDocumentsError"
        >
          {{ UpdateDocumentsError }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="UpdateDocumentsErrorDocument"
        >
          {{ UpdateDocumentsErrorDocument }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="UpdateDocumentsErrorDescription"
        >
          {{ UpdateDocumentsErrorDescription }}
        </div>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label for="">New Document</label>
          <span class="text-danger" style="font-size: 14px"
            >&nbsp;<strong>*</strong></span
          >
          <input
            type="file"
            multiple
            accept=".pdf,.doc,.docx"
            max-file-size="10000000"
            formControlName="document"
            class="form-control"
            (change)="attachFiles($event)"
            [ngClass]="{
              'is-invalid': submitted && documentDetails['document'].errors
            }"
          />

          <div
            style="color: red; font-size: 14px"
            *ngIf="
              submitted &&
              documentDetails['document'] &&
              documentDetails['document'].errors &&
              documentDetails['document'].errors['required']
            "
          >
            New Document is required.
          </div>
        </div>

        <div class="mb-3">
          <label for="">Description</label>
          <span class="text-danger" style="font-size: 14px"
            >&nbsp;<strong>*</strong></span
          >
          <input
            type="text"
            placeholder="Enter description"
            formControlName="description"
            class="form-control"
            [ngClass]="{
              'is-invalid': submitted && documentDetails['description'].errors
            }"
          />

          <div
            style="color: red; font-size: 14px; text-align: left"
            class="w-100 col-12 ps-0"
            *ngIf="submitted && (documentDetails['description'].errors?.['minlength'] || documentDetails['description'].errors?.['maxlength'])"
          >
            Description must be between 1 - 15 characters.
          </div>

          <div
            style="color: red; font-size: 14px"
            *ngIf="submitted && documentDetails['description'].errors?.['required']"
          >
            Description is required.
          </div>

          <small class="text-primary">Max 15 characters.</small>
        </div>

        <div class="mb-3">
          <label for="is_resume">Is resume?</label>
          <ng-select
            id="is_resume"
            [clearable]="false"
            [items]="isResumeOptions"
            class="form-control form-select"
            bindLabel="label"
            bindValue="value"
            formControlName="is_resume"
            placeholder="Y or N"
          >
          </ng-select>
        </div>
      </div>
      <div class="modal-footer">
        <div class="spinner-border" *ngIf="projectapicall">
          <span class="visually-hidden">Loading...</span>
        </div>
        <button
          type="button"
          class="filter"
          (click)="modal.close('Save click'); this.submitted = false"
          *ngIf="!projectapicall"
        >
          Cancel
        </button>
        <button
          type="button"
          class="blue-btn ms-"
          (click)="UpdateDocuments()"
          *ngIf="!projectapicall"
        >
          Save
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #deleteDocument let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Delete Document</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <label for=""
          >Do you want to Delete <strong>{{ deleteName }}</strong> Document
          ?</label
        >
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary cancelbutton"
        (click)="modal.close('Save click')"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-danger dangerbtn"
        (click)="deleteDocuments()"
      >
        Delete
      </button>
    </div>
  </ng-template>

  <ng-template #DeleteCandidate let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Delete Candidate</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        Are you sure to delete <strong>{{ deleteName }}</strong> Candidate ?
      </p>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary cancelbutton"
        (click)="modal.close('Save click')"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-danger dangerbtn"
        (click)="DeleteCandidates()"
      >
        Delete
      </button>
    </div>
  </ng-template>

  <ng-template #popupForLinkedinContent let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Oops!</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="mb-3">
          <p style="margin-bottom: 0px !important">
            LinkedIn URL does not exist for this candidate.
          </p>
        </div>
      </form>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-danger dangerbtn"
        (click)="modal.dismiss('cancel click')"
      >
        Ok
      </button>
    </div>
  </ng-template>

  <ng-template #AddNewBusiness let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Add New/Expansion Business
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="
          modal.dismiss('cancel click');
          this.submitted = false;
          resetAddBusinessError()
        "
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <form [formGroup]="AddNewBusinessForm">
      <div class="modal-body">
        <div>
          <div
            style="padding: 10px; font-size: 14px; padding-left: 20px"
            class="text-danger"
            *ngIf="createClientAttachError"
          >
            {{ createClientAttachError }}
          </div>
          <div
            style="padding: 5px; font-size: 14px; padding-left: 20px"
            class="text-danger"
            *ngIf="createAttachErrorProjectid"
          >
            {{ createAttachErrorProjectid }}
          </div>
        </div>

        <div class="mb-3">
          <label for="">Client Name</label>
          <ng-select
            [items]="clientsList"
            class="form-control form-select"
            bindLabel="name"
            bindValue="id"
            (search)="onClientSearch($event)"
            (change)="onClientSearch($event)"
            formControlName="clientname"
            placeholder="Select client"
          >
          </ng-select>
        </div>
      </div>

      <div class="modal-footer">
        <div class="spinner-border" *ngIf="projectapicall">
          <span class="visually-hidden">Loading...</span>
        </div>
        <button
          type="button"
          class="filter"
          (click)="
            modal.close('Save click');
            this.submitted = false;
            resetAddBusinessError()
          "
          *ngIf="!projectapicall"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="blue-btn ms-"
          (click)="newbusinesssubmit()"
        >
          Save
        </button>
      </div>
    </form>
  </ng-template>

  <!-- Replace Client In new Expansion -->
  <ng-template #replaceClient let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Add New/Expansion Business
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click'); resetAddBusinessError()"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div
        style="padding: 10px; font-size: 14px; padding-left: 20px"
        class="text-danger"
        *ngIf="createClientAttachError"
      >
        {{ stripHtmlTags(createClientAttachError) }}
      </div>
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary cancelbutton"
        (click)="
          modal.dismiss('cancel click');
          this.submitted = false;
          resetAddBusinessError()
        "
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-danger dangerbtn"
        (click)="modal.close('Ok click')"
      >
        Confirm
      </button>
    </div>
  </ng-template>

  <!-- Delete Client -->
  <ng-template #deleteclientModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Remove New/Expansion Business Details
      </h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <label for="">Do you want to Remove New/Expansion Business ?</label>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary cancelbutton"
        (click)="modal.close('Save click')"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-danger dangerbtn"
        (click)="deleteClient()"
      >
        Delete
      </button>
    </div>
  </ng-template>

  <ng-template #ViewClientModal let-modal style="width: 1000px">
    <div style="width: 1000px" class="new">
      <div class="row">
        <div class="col-lg-12">
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Edit Client</h4>
            <button
              type="button"
              class="close"
              aria-label="Close"
              (click)="
                modal.dismiss('Cross click');
                this.uploadsubmitted = false;
                this.submitted = false;
                resetBackendClienterror()
              "
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <form [formGroup]="CreateClientForm">
            <div>
              <div
                style="padding: 10px; font-size: 14px; padding-left: 20px"
                class="text-danger"
                *ngIf="createClientError"
              >
                {{ createClientError }}
              </div>
              <div
                style="padding: 5px; font-size: 14px; padding-left: 20px"
                class="text-danger"
                *ngIf="createClientErrorName"
              >
                {{ createClientErrorName }}
              </div>
              <div
                style="padding: 10px; font-size: 14px; padding-left: 20px"
                class="text-danger"
                *ngIf="createClientErrorIndustry"
              >
                {{ createClientErrorIndustry }}
              </div>
              <div
                style="padding: 5px; font-size: 14px; padding-left: 20px"
                class="text-danger"
                *ngIf="createClientErrorContactnumber"
              >
                {{ createClientErrorContactnumber }}
              </div>
              <div
                style="padding: 10px; font-size: 14px; padding-left: 20px"
                class="text-danger"
                *ngIf="createClientErrorEmail"
              >
                {{ createClientErrorEmail }}
              </div>
              <div
                style="padding: 5px; font-size: 14px; padding-left: 20px"
                class="text-danger"
                *ngIf="createClientErrorAddress"
              >
                {{ createClientErrorAddress }}
              </div>
              <div
                style="padding: 10px; font-size: 14px; padding-left: 20px"
                class="text-danger"
                *ngIf="createClientErrorPostal"
              >
                {{ createClientErrorPostal }}
              </div>
              <div
                style="padding: 10px; font-size: 14px; padding-left: 20px"
                class="text-danger"
                *ngIf="createClientErrorState"
              >
                {{ createClientErrorState }}
              </div>
              <div
                style="padding: 10px; font-size: 14px; padding-left: 20px"
                class="text-danger"
                *ngIf="createClientErrorWebsite"
              >
                {{ createClientErrorWebsite }}
              </div>
              <div
                style="padding: 5px; font-size: 14px; padding-left: 20px"
                class="text-danger"
                *ngIf="createClientErrorDescription"
              >
                {{ createClientErrorDescription }}
              </div>
              <div
                style="padding: 5px; font-size: 14px; padding-left: 20px"
                class="text-danger"
                *ngIf="createClientErrorPrimarycontact"
              >
                {{ createClientErrorPrimarycontact }}
              </div>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label for="">Client Name</label>
                <span class="text-danger" style="font-size: 14px"
                  >&nbsp;<strong>*</strong></span
                >
                <input
                  type="text"
                  placeholder="Enter client name"
                  formControlName="name"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && client['name'].errors
                  }"
                />
                <div
                  style="color: red; font-size: 14px"
                  *ngIf="
                    submitted &&
                    client['name'] &&
                    client['name'].errors &&
                    client['name'].errors['required']
                  "
                >
                  Client Name is required.
                </div>
              </div>

              <div class="mb-3">
                <label for="">Account Owner/Originator</label>
                <span class="text-danger" style="font-size: 14px"
                  >&nbsp;<strong>*</strong></span
                >
                <ng-select
                  [items]="userList"
                  class="form-control form-select"
                  bindLabel="name"
                  bindValue="id"
                  formControlName="user_id"
                  placeholder="Select Account Owner/Originator"
                  (clear)="getUsersList()"
                  (search)="getUsersList($event)"
                >
                </ng-select>
                <div
                  style="color: red; font-size: 14px"
                  *ngIf="
                    submitted &&
                    client['user_id'] &&
                    client['user_id'].errors &&
                    client['user_id'].errors['required']
                  "
                >
                  Account Owner/Originator is required.
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-6">
                  <label for="">Industry</label>
                  <input
                    type="text"
                    placeholder="Enter industry"
                    formControlName="industry"
                    class="form-control"
                  />
                </div>

                <div class="col-6">
                  <label for="">No. of Searches</label>
                  <input
                    type="text"
                    formControlName="no_of_searches"
                    class="form-control"
                  />
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-6">
                  <label for="">Client Stage</label>
                  <select
                    name=""
                    id=""
                    class="form-select"
                    formControlName="client_status_id"
                  >
                    <option value="0">Select Client Stage</option>
                    <option
                      value="{{ cs.id }}"
                      *ngFor="let cs of clientStatusList"
                    >
                      {{ cs.name }}
                    </option>
                  </select>
                </div>

                <div class="col-6">
                  <label for="">Client Probability</label>
                  <select
                    name=""
                    id=""
                    class="form-select"
                    formControlName="client_probability_status_id"
                  >
                    <option value="0">Select Probability</option>
                    <option
                      *ngFor="let status of ClientProbabiltylist"
                      [value]="status.id"
                    >
                      {{ status.name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-check pt-1 mb-3">
                <input
                  class="form-check-input"
                  title="is_referenceable"
                  type="checkbox"
                  value=""
                  formControlName="is_referenceable"
                />
                <label
                  class="form-check-label"
                  title="is_referenceable"
                  for="flexCheckDefault"
                >
                  Referenceable Client
                </label>
              </div>

              <!-- <div class="mb-3">
                <div class="row">
                  <label for="" style="margin: 0px">Primary Contact </label>
                  <div style="margin-left: 46%; font-size: x-small">OR</div>
                  <div class="col-6">
                    <ng-select
                      [items]="candidateList"
                      (change)="populateEmail($event)"
                      (keyup)="populateEmail($event)"
                      class="form-control form-select"
                      bindLabel="full_name"
                      bindValue="full_name"
                      formControlName="primary_contact"
                      placeholder="Pick from Candidates"
                    >
                    </ng-select>
                  </div>

                  <div class="col-6">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="primary_contact_static"
                      placeholder="Enter Contact Name"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="mb-3">
                    <label for="">Phone</label>
                    <input
                      type="text"
                      maxlength="14"
                      placeholder="Enter contact number"
                      formControlName="contact_number"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid':
                          submitted && client['contact_number'].errors
                      }"
                    />
                    <div
                      style="color: red; font-size: 14px"
                      *ngIf="
                        client['contact_number'] &&
                        client['contact_number'].errors &&
                        client['contact_number'].errors['pattern']
                      "
                    >
                      Please enter valid phone number.
                    </div>
                  </div>

                  <div class="mb-3" hidden>
                    <label for="">Candidate Id</label>
                    <input
                      type="text"
                      formControlName="candidate_id"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                    <label for="">Email</label>
                    <input
                      type="text"
                      placeholder="Enter email "
                      formControlName="contact_email"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid':
                          submitted && client['contact_email'].errors
                      }"
                    />
                    <div
                      style="color: red; font-size: 14px"
                      *ngIf="
                        client['contact_email'] &&
                        client['contact_email'].errors &&
                        client['contact_email'].errors['pattern']
                      "
                    >
                      Please enter valid Email address.
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="mb-3">
                <label for="">Street address</label>
                <input
                  type="text"
                  placeholder="Enter street address"
                  formControlName="street_address"
                  class="form-control"
                />
              </div>
              <div class="row">
                <div class="col-6">
                  <div class="mb-3">
                    <label for="">Postal code</label>
                    <input
                      type="text"
                      placeholder="Enter postal code"
                      formControlName="postal_code"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                    <label for="">City</label>
                    <input
                      type="text"
                      placeholder="Enter city"
                      formControlName="city"
                      class="form-control"
                      [ngClass]="{
                        'is-invalid': submitted && client['city'].errors
                      }"
                    />
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-6">
                  <div class="mb-3">
                    <label for="">State</label>
                    <ng-select
                      [items]="stateList"
                      class="form-control form-select"
                      bindLabel="name"
                      bindValue="name"
                      formControlName="state"
                      placeholder="Select state"
                    >
                    </ng-select>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mb-3">
                    <label for="">Website</label>
                    <input
                      type="text"
                      placeholder="Enter website"
                      formControlName="website"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <label for="">Description</label>
                <textarea
                  type="text"
                  placeholder="Enter description"
                  formControlName="description"
                  class="form-control"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <div class="spinner-border" *ngIf="projectapicall">
                <span class="visually-hidden">Loading...</span>
              </div>
              <button
                type="button"
                class="filter"
                (click)="
                  modal.close('Save click');
                  this.uploadsubmitted = false;
                  this.submitted = false;
                  resetBackendClienterror()
                "
                *ngIf="!projectapicall"
              >
                Cancel
              </button>
              <button
                type="button"
                class="blue-btn ms-"
                (click)="UpdateClients()"
                *ngIf="!projectapicall"
              >
                Save
              </button>
            </div>
          </form>
        </div>

        <div class="col-lg-1" style="width: 0.33333%">
          <div class="vl"></div>
        </div>

        <div class="col-lg-5" style="padding: 0px 0px 0px 10px">
          <div
            class="modal-header"
            style="border-bottom: unset !important; padding-bottom: 0px"
          >
            <div style="display: flex">
              <h5 class="modal-title" id="modal-basic-title">
                <strong>Documents</strong>
              </h5>
            </div>
          </div>
          <form [formGroup]="CreateClientForm">
            <div>
              <div
                style="padding: 5px; font-size: 14px; padding-left: 20px"
                class="text-danger"
                *ngIf="CreatDocumentsErrorDocuments"
              >
                {{ CreatDocumentsErrorDocuments }}
              </div>
              <div
                style="padding: 5px; font-size: 14px; padding-left: 20px"
                class="text-danger"
                *ngIf="createClientErrorWeblinkone"
              >
                {{ createClientErrorWeblinkone }}
              </div>
              <div
                style="padding: 5px; font-size: 14px; padding-left: 20px"
                class="text-danger"
                *ngIf="createClientErrorWeblinktwo"
              >
                {{ createClientErrorWeblinktwo }}
              </div>
              <div
                style="padding: 5px; font-size: 14px; padding-left: 20px"
                class="text-danger"
                *ngIf="createClientErrorWeblinkthree"
              >
                {{ createClientErrorWeblinkthree }}
              </div>
              <div
                style="padding: 5px; font-size: 14px; padding-left: 20px"
                class="text-danger"
                *ngIf="createClientErrorLogo"
              >
                {{ createClientErrorLogo }}
              </div>
              <div
                style="padding: 5px; font-size: 14px; padding-left: 20px"
                class="text-danger"
                *ngIf="CreatDocumentsErrorDocumentsname"
              >
                {{ CreatDocumentsErrorDocumentsname }}
              </div>
            </div>

            <div class="modal-body">
              <div class="row">
                <div class="col-8">
                  <input
                    type="file"
                    multiple
                    accept=".pdf,.doc,.docx"
                    placeholder="Document is required"
                    max-file-size="10000000"
                    formControlName="document"
                    class="form-control"
                    (change)="attachFiles2($event)"
                  />
                </div>
                <div class="col-4">
                  <button
                    type="button"
                    class="blue-btn ms-"
                    (click)="UploadDocuments2()"
                    style="float: right"
                    *ngIf="!projectapicall2 && showUploadFileButton"
                  >
                    Upload
                  </button>
                </div>
              </div>

              <div class="row" style="margin-top: 15px">
                <div class="col-lg-12">
                  <div *ngIf="projectapicall">{{ fileName }}</div>
                  <div class="row">
                    <div
                      id="panelsStayOpen-collapsesix"
                      class="accordion-collapse collapse show scrolldoc"
                      aria-labelledby="panelsStayOpen-headingsix"
                    >
                      <div
                        class="accordion-body"
                        *ngFor="let document of documentData"
                      >
                        <div class="experience d-flex justify-content-between">
                          <div class="experience-left col-lg-10">
                            <h6 class="modal-title" id="modal-basic-title">
                              <a
                                href="{{ document?.path }}"
                                target="_blank"
                                style="
                                  color: gray;
                                  text-decoration: unset !important;
                                  font-size: 14px;
                                "
                              >
                                {{ document?.name }}</a
                              >
                            </h6>
                          </div>
                          <div
                            class="inner-edit col-lg-2"
                            style="text-align: right"
                          >
                            <a
                              href="javascript:void(0)"
                              (click)="deleteDocuments1(document.id)"
                            >
                              <em
                                class="bi bi-trash"
                                title="Delete"
                                style="color: black"
                              ></em
                            ></a>
                          </div>
                        </div>
                        <hr style="margin-top: 2px" />
                      </div>
                    </div>
                    <div
                      class="accordion-body"
                      *ngIf="documentData?.length == 0"
                      style="margin-left: 20px"
                    >
                      No Documents added
                    </div>

                    <div style="margin-top: 10px">
                      <div class="spinner-border" *ngIf="projectapicall2">
                        <span class="visually-hidden">Uploading...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" style="margin-top: 20px">
                <div
                  class="row"
                  style="display: flex; justify-content: space-between"
                >
                  <label for="">Web link 1</label>
                  <label for="" *ngIf="showexternallink1 && !editexternallink1"
                    ><span
                      (click)="
                        editexternallink1 = true; showexternallink1 = false
                      "
                      >{{ weblink1 | slice : 0 : 40
                      }}{{
                        weblink1.length > 40
                          ? "...
                      "
                          : " "
                      }}</span
                    ><a
                      href="{{ weblink1 }}"
                      target="_blank"
                      title="Open web link"
                      ><em
                        class="fa fa-external-link"
                        aria-hidden="true"
                      ></em> </a
                  ></label>
                  <input
                    form=""
                    *ngIf="!showexternallink1 && editexternallink1"
                    type="text"
                    placeholder="Enter URL"
                    formControlName="web_link_one"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': submitted && client['web_link_one'].errors
                    }"
                  />
                  <div
                    style="color: red; font-size: 14px"
                    *ngIf="
                      client['web_link_one'] &&
                      client['web_link_one'].errors &&
                      client['web_link_one'].errors['pattern']
                    "
                  >
                    Please enter a valid URL. (i.e. http://www.google.com)
                  </div>
                </div>
                <div
                  class="row"
                  style="display: flex; justify-content: space-between"
                >
                  <label for="">Web link 2</label>
                  <label for="" *ngIf="showexternallink2 && !editexternallink2"
                    ><span
                      (click)="
                        editexternallink2 = true; showexternallink2 = false
                      "
                      >{{ weblink2 | slice : 0 : 40
                      }}{{
                        weblink2.length > 40
                          ? "...
                      "
                          : " "
                      }} </span
                    ><a
                      href="{{ weblink2 }}"
                      target="_blank"
                      title="Open web link"
                    >
                      <em
                        class="fa fa-external-link"
                        aria-hidden="true"
                      ></em> </a
                  ></label>

                  <input
                    form=""
                    *ngIf="!showexternallink2 && editexternallink2"
                    type="text"
                    placeholder="Enter URL"
                    formControlName="web_link_two"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': submitted && client['web_link_two'].errors
                    }"
                  />
                  <div
                    style="color: red; font-size: 14px"
                    *ngIf="
                      client['web_link_two'] &&
                      client['web_link_two'].errors &&
                      client['web_link_two'].errors['pattern']
                    "
                  >
                    Please enter a valid URL. (i.e. http://www.google.com)
                  </div>
                </div>
                <div
                  class="row"
                  style="display: flex; justify-content: space-between"
                >
                  <label for="" *ngIf="!showexternallink3">Web link 3</label>
                  <label for="" *ngIf="showexternallink3 && !editexternallink3"
                    ><span
                      (click)="
                        editexternallink3 = true; showexternallink3 = false
                      "
                      >{{ weblink3 | slice : 0 : 40
                      }}{{
                        weblink3.length > 40
                          ? "...
                      "
                          : " "
                      }}</span
                    >
                    <a
                      href="{{ weblink3 }}"
                      target="_blank"
                      title="Open web link"
                      ><em
                        class="fa fa-external-link"
                        aria-hidden="true"
                      ></em> </a
                  ></label>

                  <input
                    *ngIf="!showexternallink3 && editexternallink3"
                    type="text"
                    placeholder="Enter URL"
                    formControlName="web_link_three"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': submitted && client['web_link_three'].errors
                    }"
                  />
                  <div
                    style="color: red; font-size: 14px"
                    *ngIf="
                      client['web_link_three'] &&
                      client['web_link_three'].errors &&
                      client['web_link_three'].errors['pattern']
                    "
                  >
                    Please enter a valid URL. (i.e. http://www.google.com)
                  </div>
                </div>
              </div>

              <div class="row" style="margin-top: 20px">
                <div>
                  <h5
                    class="modal-title"
                    id="modal-basic-title"
                    style="float: left"
                  >
                    <strong>Client Logo</strong>
                  </h5>
                  <button
                    type="button"
                    class="blue-btn"
                    (click)="selectFile2.click()"
                    *ngIf="logoBox2"
                    style="font-size: 14px !important; float: right"
                  >
                    Change
                  </button>
                </div>
                <input
                  type="file"
                  id="file-input2"
                  style="display: none"
                  accept="image/*"
                  #selectFile2
                  max-file-size="1024"
                  formControlName="logo"
                  class="form-control"
                  (change)="attachLogoFiles2($event)"
                />
              </div>

              <div class="row" style="margin-top: 10px">
                <div
                  *ngIf="rectangleBox"
                  id="panelsStayOpen-collapsesix"
                  class="accordion-collapse collapse show scrolldocument"
                  aria-labelledby="panelsStayOpen-headingsix"
                >
                  <label
                    for="file-input3"
                    class="accordion-body clientlogobg file full-width-label"
                  >
                    <span>Choose File</span>
                    <input
                      type="file"
                      id="file-input3"
                      style="display: none"
                      accept="image/*"
                      #selectFile3
                      max-file-size="1024"
                      formControlName="logo2"
                      class="form-control"
                      (change)="attachLogoFiles2($event)"
                    />
                  </label>
                </div>
                <div
                  class="col-lg-12 row"
                  style="margin-top: 10px"
                  *ngIf="logoBox2"
                >
                  <div class="col-12" style="padding-right: 0px">
                    <img
                      [src]="url2"
                      *ngIf="url2 !== null"
                      height="120"
                      class="clientlogobgimg img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
</div>
