import {
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy,
  HostListener,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {
  StorageKey,
  StorageService,
} from 'src/app/core/common/storage.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ProjectService } from 'src/app/core/services/projects/project.service';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientService } from 'src/app/core/services/clients/client.service';
import { PagerService } from 'src/app/core/common/pager.service';
import { UserService } from 'src/app/core/services/users/user.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';

export function endDateValidator(
  control: AbstractControl
): { [key: string]: boolean } | null {
  const startDate = control.get('official_start_date');
  const endDate = control.get('official_end_date');

  if (
    startDate &&
    endDate &&
    startDate.value &&
    endDate.value &&
    startDate.value > endDate.value
  ) {
    return { endDateInvalid: true };
  }
  return { endDateInvalid: false };
}

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.css'],
})
export class ProjectListComponent implements OnInit, OnDestroy {
  private routeSubscription: Subscription = new Subscription();
  FilterForm!: FormGroup;
  projectList: any = [];
  lastSortedField: string = '';
  lastSortedDirection: string = '';
  clientList = [];
  projectTypeList = [];
  projectStatusList = [];
  stateList = [];
  statusofprojectList = [];
  activePage: number = 1;
  submitted: boolean = false;
  total: number = 10;
  to: number = 10;
  pageTitle: string = 'Searches';
  isSearchable: boolean = true;
  showCreateButton: string = 'project';
  createProjectError: string = '';
  UpdateProjectForm!: FormGroup;
  closeResult: string = '';
  pageNumber: number = 1;
  pageSize: number = 3;
  totalRows: number = 0;
  pager: any = {};
  showFilterOptions: boolean = false;
  selectDeletedProject: string | undefined;
  projectapicall: boolean = false;
  selectedclientValue: any;
  // for sorting list
  faArrowUp = faCaretUp;
  faArrowDown = faCaretDown;
  sortfield: any;
  sortmethod: any;
  iconVisible: boolean = true;
  iconVisibleAsc: boolean = false;
  iconVisibleDesc: boolean = false;
  staticarrowsVisible: boolean = false;
  currentSortedColumn: any;
  currentSortedMethod: any;
  sortedColumn: any;
  clientSearchValue: string = '';
  createProjectErrorName: any;
  createProjectErrorClientname: any;
  createProjectErrorType: any;
  createProjectErrorProjectstatusid: any;
  createProjectErrorOfficialstartdate: any;
  createProjectErrorOfficialenddate: any;
  createProjectErrorStreetaddress: any;
  createProjectErrorPostalcode: any;
  createProjectErrorCity: any;
  createProjectErrorState: any;
  createProjectErrorDescription: any;
  clientsList = [];
  currentSearchValue: string = '';
  currentAppliedFilter: any;
  searchListSettings: any;
  getSearchList: any;
  usersList: any;

  fileName!: string;
  filePath!: string;
  files: any;
  openDocumentSection: boolean = false;
  openDocumentButtton: boolean = true;
  documentData: any;
  ProjectdocId!: number;
  deleteDocumentId: any;
  uploadsubmitted: boolean = false;
  CreatDocumentsErrorDocuments: any;
  projectapicall2: boolean = false;
  CreatDocumentsErrorDocumentsname: any;
  showexternallink1: boolean = false;
  showexternallink2: boolean = false;
  showexternallink3: boolean = false;
  editexternallink1: boolean = false;
  editexternallink2: boolean = false;
  editexternallink3: boolean = false;
  publishOptions = [
    { label: 'Yes', value: '1' },
    { label: 'No', value: '0' },
  ];
  isSearchClicked: boolean = false;
  isClearClicked: boolean = false;
  resetButtonClicked: boolean = false;

  private hasScrolledToBottom = false;

  weblink1: string = '';
  weblink2: string = '';
  weblink3: string = '';
  createProjectErrorWeblinkone: any;
  createProjectErrorWeblinktwo: any;
  createProjectErrorWeblinkthree: any;
  userList: any;
  updateProjectError: any;
  mouseOnColumnName: string = '';
  tenantRoleNotshow: boolean = false;
  is_client_module_access_disabled: any;

  @ViewChild('ispublic') ispublic: any;

  updateJobPostingForm: FormGroup;

  is_public: any = '0';

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'insertImage',
        'subscript',
        'superscript',
        'clearFormatting',
        'heading',
        'insertVideo',
        'customClasses',
        'toggleEditorMode',
      ],
    ],
  };

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private authservice: AuthService,
    private storageService: StorageService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private modalservice: NgbModal,
    private clientService: ClientService,
    private pagerService: PagerService,
    private userService: UserService
  ) {
    this.updateJobPostingForm = this.formBuilder.group({
      projectId: [null],
      name: [null, [Validators.required]],
      description: [null],
      project_status_id: [null],
      city: [null],
      state: [null],
      is_public: [null],
      is_publish: [null],
      clientname: [null],
    });

    this.FilterForm = this.formBuilder.group({
      f_project_status: [null],
      f_project_type: [null],
      f_client: [null],
      f_city: [null],
      f_state: [null],
    });

    // this.pageTitle = localStorage.getItem('page_name') || 'Searches';
    this.is_client_module_access_disabled = this.storageService.getValue(
      'is_client_module_access_disabled'
    );
  }

  get f() {
    return this.UpdateProjectForm.controls;
  }
  get jobupdate() {
    return this.updateJobPostingForm.controls;
  }
  get filterForm() {
    return this.FilterForm.controls;
  }

  // ngOnInit(): void {

  //   this.onLoad();
  //   this.pageTitle = localStorage.getItem('page_name') || 'Searches';
  //   // console.log('Initial pageTitle:', this.pageTitle);

  //   if (this.pageTitle === 'Job Posting') {
  //     this.is_public = 1;
  //   } else {
  //     this.is_public = 0;
  //   }
  //   let data = {
  //     is_public: this.is_public,
  //     page: 1,
  //     sort_field: '',
  //     sort_method: '',
  //     column_name: '',
  //     search: '',
  //     apply_filter: '',
  //     user_id: null
  //   };
  //   this.getProjectList(null, data, false);
  //   // console.log('Initial is_public:', this.is_public);

  //   // this.pageTitle = "Searches";
  //   this.routeSubscription = this.router.events.pipe(
  //     filter(event => event instanceof NavigationEnd)
  //   ).subscribe(() => {
  //     // Retrieve query parameter 'type'
  //     // let typeQueryParam = this.router.routerState.snapshot.root.queryParams['type'] || '';
  //     // // Set is_public based on typeQueryParam
  //     // if (typeQueryParam === 'search') {
  //     //   this.is_public = 0;
  //     //   this.pageTitle = "Searches";
  //     //   localStorage.setItem('page_name', "Searches");
  //     // } else if (typeQueryParam === 'jobposting') {
  //     //   this.is_public = 1;
  //     //   this.pageTitle = "Job Posting";
  //     //   localStorage.setItem('page_name', "Job Posting");
  //     // }

  //     // Retrieve query parameter 'type'
  //     let typeQueryParam = this.router.routerState.snapshot.root.queryParams['type'] || '';
  //     // Set is_public based on typeQueryParam
  //     if (typeQueryParam === 'search') {
  //       this.is_public = 0;
  //       this.pageTitle = "Searches";
  //       localStorage.setItem('page_name', "Searches");
  //     } else if (typeQueryParam === 'jobposting') {
  //       this.is_public = 1;
  //       this.pageTitle = "Job Posting";
  //       localStorage.setItem('page_name', "Job Posting");
  //     }

  //     // console.log('Updated pageTitle:', this.pageTitle);
  //     // console.log('Updated is_public:', this.is_public);

  //     let data = {
  //       is_public: this.is_public,
  //       page: 1,
  //       sort_field: '',
  //       sort_method: '',
  //       column_name: '',
  //       search: '',
  //       apply_filter: '',
  //       user_id: null
  //     };

  //     // this.onLoad();
  //     this.getProjectList(null, data, false);
  //   });

  // }

  // ngOnInit(): void {
  //   this.onLoad();
  //   this.pageTitle = localStorage.getItem('page_name') || 'Searches';
  //   this.is_public = this.pageTitle === 'Job Posting' ? 1 : 0;
  //   this.setProjectList(); // Call the function to set project list initially

  //   this.routeSubscription = this.router.events.pipe(
  //     filter(event => event instanceof NavigationEnd)
  //   ).subscribe(() => {
  //     let typeQueryParam = this.router.routerState.snapshot.root.queryParams['type'] || '';
  //     if (typeQueryParam === 'search') {
  //       this.is_public = 0;
  //       this.pageTitle = "Searches";
  //       this.setProjectList(); // Call the function to set project list
  //     } else if (typeQueryParam === 'jobposting') {
  //       this.is_public = 1;
  //       this.pageTitle = "Job Posting";
  //       this.setProjectList(); // Call the function to set project list
  //     }
  //   });
  // }

  // Function to set project list based on current state
  // setProjectList() {
  //   let data = {
  //     is_public: this.is_public,
  //     page: 1,
  //     sort_field: '',
  //     sort_method: '',
  //     column_name: '',
  //     search: '',
  //     apply_filter: '',
  //     user_id: null
  //   };
  //   this.getProjectList(null, data, false);
  // }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any) {
    if (this.projectList.length < this.pager.totalItems) {
      const scrollPosition = window.scrollY || window.pageYOffset;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollPosition + windowHeight >= documentHeight - 10) {
        if (!this.hasScrolledToBottom) {
          this.hasScrolledToBottom = true;
          this.setPage(this.pager.currentPage + 1);
        }
      } else {
        this.hasScrolledToBottom = false;
      }
    }
  }

  ngOnInit(): void {
    let listSettings: any = this.storageService.getValue(
      StorageKey.listSettings
    );
    if (listSettings !== 'undefined' && listSettings)
      this.getSearchList = JSON.parse(listSettings);
    if (this.getSearchList && this.getSearchList.searches) {
      let existing_filter = this.getSearchList.searches.apply_filter;
      if (existing_filter) {
        this.showFilterOptions = true;
        let apply_filter = existing_filter.split('&');
        apply_filter.forEach((e: any) => {
          let f_option = e.split('=');
          if (f_option[0] == 'project_status_id')
            this.FilterForm.controls['f_project_status'].setValue(f_option[1]);

          if (f_option[0] == 'project_type_id')
            this.FilterForm.controls['f_project_type'].setValue(f_option[1]);

          if (f_option[0] == 'client_id')
            this.FilterForm.controls['f_client'].setValue(f_option[1]);

          if (f_option[0] == 'search_city')
            this.FilterForm.controls['f_city'].setValue(f_option[1]);

          if (f_option[0] == 'search_state')
            this.FilterForm.controls['f_state'].setValue(f_option[1]);
        });

        this.getProjectList(null, this.getSearchList.searches, false);
      }
    }

    this.pageTitle = localStorage.getItem('page_name') || 'Searches';
    this.is_public = this.pageTitle === 'Job Posting' ? 1 : 0;
    this.setProjectList(); // Call the function to set project list initially

    this.routeSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        let typeQueryParam =
          this.router.routerState.snapshot.root.queryParams['type'] || '';
        if (
          (typeQueryParam === 'search' && this.pageTitle === 'Job Posting') ||
          (typeQueryParam === 'jobposting' && this.pageTitle === 'Searches')
        ) {
          window.location.reload();
        }
        if (typeQueryParam === 'search') {
          this.pageTitle = 'Searches';
          this.is_public = 0;
        } else if (typeQueryParam === 'jobposting') {
          this.pageTitle = 'Job Posting';
          this.is_public = 1;
        }
        this.setProjectList(); // Call the function to set project list after updating variables
      });
  }

  setProjectList() {
    let data = {
      // is_public: this.is_public,
      // page: this.pageNumber,
      // sort_field: '',
      // sort_method: '',
      // column_name: '',
      // search: '',
      // apply_filter: this.currentAppliedFilter,
      // user_id: null
      is_public: this.is_public,
      page: this.pageNumber,
      sort_field: this.currentSortedColumn,
      sort_method: this.currentSortedMethod,
      column_name: this.sortedColumn,
      search: this.currentSearchValue,
      apply_filter: this.currentAppliedFilter,
      user_id: null,
    };
    this.getProjectList(null, data, false);
  }

  onLoad() {
    let listSettings: any = this.storageService.getValue(
      StorageKey.listSettings
    );
    if (listSettings !== 'undefined' && listSettings)
      this.getSearchList = JSON.parse(listSettings);
    if (this.getSearchList && this.getSearchList.searches) {
      let existing_filter = this.getSearchList.searches.apply_filter;
      if (existing_filter) {
        this.showFilterOptions = true;
        let apply_filter = existing_filter.split('&');
        apply_filter.forEach((e: any) => {
          let f_option = e.split('=');
          if (f_option[0] == 'project_status_id')
            this.FilterForm.controls['f_project_status'].setValue(f_option[1]);

          if (f_option[0] == 'project_type_id')
            this.FilterForm.controls['f_project_type'].setValue(f_option[1]);

          if (f_option[0] == 'client_id')
            this.FilterForm.controls['f_client'].setValue(f_option[1]);

          if (f_option[0] == 'search_city')
            this.FilterForm.controls['f_city'].setValue(f_option[1]);

          if (f_option[0] == 'search_state')
            this.FilterForm.controls['f_state'].setValue(f_option[1]);
        });
      }
      // this.getProjectList(null, this.getSearchList.searches);
    }
    // else
    // this.getProjectList({});
  }

  createProjectForm() {
    this.UpdateProjectForm = this.formBuilder.group(
      {
        projectId: [null],
        name: [null, [Validators.required]],
        clientname: [null],
        official_start_date: [null, [Validators.required]],
        official_end_date: [null],
        street_address: [null],
        postal_code: [null],
        city: [null],
        state: [null],
        type: [null],
        description: [null],
        project_status_id: [null],
        web_link_one: [null, [Validators.pattern(this.getURLPattern())]],
        web_link_two: [null, [Validators.pattern(this.getURLPattern())]],
        web_link_three: [null, [Validators.pattern(this.getURLPattern())]],
        document: [null],
        user: [null],
        is_public: [null],
      },
      { validator: endDateValidator }
    );
  }

  private getURLPattern(): string {
    return '^(http|https)://([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?$';
  }

  EditProject(content: any, EditId: number) {
    // this.modalservice.open("UpdateProject");

    if (EditId != undefined || EditId != null) {
      this.projectService
        .getProjectData({ id: EditId })
        .subscribe((data: any) => {
          let response = data.project;
          this.ProjectdocId = data.project.id;
          this.getDocumentData();

          if (
            response.web_link_one !== 'null' &&
            response.web_link_one != null
          ) {
            this.UpdateProjectForm.controls['web_link_one'].setValue(
              response.web_link_one
            );
            this.showexternallink1 = true;
            this.weblink1 = response.web_link_one;
            this.editexternallink1 = false;
          } else {
            this.UpdateProjectForm.controls['web_link_one'].setValue('');
            this.showexternallink1 = false;
            this.weblink1 = '';
            this.editexternallink1 = true;
          }

          if (
            response.web_link_two !== 'null' &&
            response.web_link_two != null
          ) {
            this.UpdateProjectForm.controls['web_link_two'].setValue(
              response.web_link_two
            );
            this.showexternallink2 = true;
            this.weblink2 = response.web_link_two;
            this.editexternallink2 = false;
          } else {
            this.UpdateProjectForm.controls['web_link_two'].setValue('');
            this.showexternallink2 = false;
            this.weblink2 = '';
            this.editexternallink2 = true;
          }

          if (
            response.web_link_three !== 'null' &&
            response.web_link_three != null
          ) {
            this.UpdateProjectForm.controls['web_link_three'].setValue(
              response.web_link_three
            );
            this.showexternallink3 = true;
            this.weblink3 = response.web_link_three;
            this.editexternallink3 = false;
          } else {
            this.UpdateProjectForm.controls['web_link_three'].setValue('');
            this.showexternallink3 = false;
            this.weblink3 = '';
            this.editexternallink3 = true;
          }

          this.UpdateProjectForm.controls['projectId'].setValue(response.id);
          this.UpdateProjectForm.controls['name'].setValue(response.name);
          if (response.client && response.client.id)
            this.UpdateProjectForm.controls['clientname'].setValue(
              response.client.id
            );
          this.UpdateProjectForm.controls['official_start_date'].setValue(
            response.official_start_date
          );
          this.UpdateProjectForm.controls['official_end_date'].setValue(
            response.official_end_date
          );
          this.UpdateProjectForm.controls['city'].setValue(response.city);
          this.UpdateProjectForm.controls['state'].setValue(response.state);
          this.UpdateProjectForm.controls['postal_code'].setValue(
            response.postal_code
          );
          this.UpdateProjectForm.controls['street_address'].setValue(
            response.street_address
          );
          this.UpdateProjectForm.controls['description'].setValue(
            response.description
          );
          if (response.type && response.type.id)
            this.UpdateProjectForm.controls['type'].setValue(response.type.id);
          this.UpdateProjectForm.controls['project_status_id'].setValue(
            response.status.id
          );
          this.UpdateProjectForm.controls['user'].setValue(
            response.users.map((a: any) => a.id)
          );

          // is public

          if (response.is_public === 1) {
            this.updateJobPostingForm.controls['projectId'].setValue(
              response.id
            );
            this.updateJobPostingForm.controls['name'].setValue(response.name);
            this.updateJobPostingForm.controls['city'].setValue(response.city);
            this.updateJobPostingForm.controls['state'].setValue(
              response.state
            );
            this.updateJobPostingForm.controls['is_publish'].setValue(
              response.is_publish
            );
            this.updateJobPostingForm.controls['description'].setValue(
              response.description
            );
            this.updateJobPostingForm.controls['project_status_id'].setValue(
              response.status.id
            );
            if (response.client && response.client.id) {
              this.updateJobPostingForm.controls['clientname'].setValue(
                response.client.id
              );
            }

            this.modalservice
              .open(this.ispublic, {
                ariaLabelledBy: 'modal-basic-title',
                size: 'xl',
              })
              .result.then(
                (result) => {
                  this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                  this.closeResult = `Dismissed `;
                }
              );
          } else {
            this.modalservice
              .open(content, {
                ariaLabelledBy: 'modal-basic-title',
                size: 'md',
              })
              .result.then(
                (result) => {
                  this.closeResult = `Closed with: ${result}`;
                },
                (reason) => {
                  this.closeResult = `Dismissed `;
                }
              );
          }
        });
    }
  }

  open(content: any, EditId: number) {
    this.EditProject(content, EditId);
    this.createProjectForm();
    // this.getClientList();
    this.getProjectTypeList();
    this.getStateList();
    this.getClientsList();
    this.getStatusofprojectList();
    this.getUsersList();
  }

  // Updatw Search
  UpdateProjects() {
    this.submitted = true;
    if (this.f['name'].errors || this.f['official_start_date'].errors) {
      return;
    }

    let userids = [];
    if (this.UpdateProjectForm.controls['user'].value)
      userids = this.UpdateProjectForm.controls['user'].value;

    let userRole = this.storageService.getValue(StorageKey.currentUserRoleId);
    let currentUserId = this.storageService.getValue(StorageKey.currentUserid);
    if (
      (userRole == '2' &&
        userids.length > 0 &&
        !userids.includes(currentUserId)) ||
      (userRole == '2' && userids.length == 0)
    )
      userids.push(currentUserId);

    let UpdatedProjectDetails = {
      id: this.UpdateProjectForm.controls['projectId'].value,
      name: this.UpdateProjectForm.controls['name'].value,
      client_id: this.UpdateProjectForm.controls['clientname'].value,
      official_start_date:
        this.UpdateProjectForm.controls['official_start_date'].value,
      official_end_date:
        this.UpdateProjectForm.controls['official_end_date'].value,
      city: this.UpdateProjectForm.controls['city'].value,
      state: this.UpdateProjectForm.controls['state'].value,
      postal_code: this.UpdateProjectForm.controls['postal_code'].value,
      street_address: this.UpdateProjectForm.controls['street_address'].value,
      description: this.UpdateProjectForm.controls['description'].value,
      project_type_id: this.UpdateProjectForm.controls['type'].value,
      project_status_id: Number(
        this.UpdateProjectForm.controls['project_status_id'].value
      ),
      web_link_one: this.UpdateProjectForm.controls['web_link_one'].value,
      web_link_two: this.UpdateProjectForm.controls['web_link_two'].value,
      web_link_three: this.UpdateProjectForm.controls['web_link_three'].value,
      user_ids: this.UpdateProjectForm.controls['user'].value,
      is_public: this.UpdateProjectForm.controls['is_public'].value ?? 0,
    };

    this.projectapicall = true;
    this.projectService
      .updateProjectData(UpdatedProjectDetails.id, UpdatedProjectDetails)
      .subscribe({
        next: (response: any) => {
          this.modalservice.dismissAll();
          this.UpdateProjectForm.reset();
          this.notificationService.showSuccess(response.message);
          // window.location.reload();
          this.projectapicall = false;
          this.submitted = false;
          // this.getProjectList({});
          for (let i = 0; i < this.projectList.length; i++) {
            if (this.projectList[i].id == UpdatedProjectDetails.id) {
              // console.log(this.projectList[i].id);
              this.projectList[i] = response.project;
              break;
            }
          }
        },
        error: (error: any) => {
          this.projectapicall = false;
          this.createProjectError = error.error.message;
          switch (error.status) {
            case 422:
              if (error.error.name) {
                this.createProjectErrorName = error.error.name[0];
              }
              if (error.error.client_id) {
                this.createProjectErrorClientname = error.error.client_id[0];
              }
              if (error.error.type) {
                this.createProjectErrorType = error.error.type[0];
              }
              if (error.error.project_status_id) {
                this.createProjectErrorProjectstatusid =
                  error.error.project_status_id[0];
              }
              if (error.error.official_start_date) {
                this.createProjectErrorOfficialstartdate =
                  error.error.official_start_date[0];
              }
              if (error.error.official_end_date) {
                this.createProjectErrorOfficialenddate =
                  error.error.official_end_date[0];
              }
              if (error.error.street_address) {
                this.createProjectErrorStreetaddress =
                  error.error.street_address[0];
              }
              if (error.error.postal_code) {
                this.createProjectErrorPostalcode = error.error.postal_code[0];
              }
              if (error.error.city) {
                this.createProjectErrorCity = error.error.city[0];
              }
              if (error.error.state) {
                this.createProjectErrorState = error.error.state[0];
              }
              if (error.error.description) {
                this.createProjectErrorDescription = error.error.description[0];
              }
              if (error.error.web_link_one) {
                this.createProjectErrorWeblinkone = error.error.web_link_one[0];
              }
              if (error.error.web_link_two) {
                this.createProjectErrorWeblinktwo = error.error.web_link_two[0];
              }
              if (error.error.web_link_three) {
                this.createProjectErrorWeblinkthree =
                  error.error.web_link_three[0];
              }

              break;

            default:

            //Nothing
          }
          //  this.notificationService.showError(error.message);
        },
      });
  }

  resetBackendProjecterror() {
    this.createProjectErrorName = '';
    this.createProjectErrorDescription = '';
    this.createProjectErrorClientname = '';
    this.createProjectErrorOfficialstartdate = '';
    this.createProjectErrorType = '';
    this.createProjectErrorProjectstatusid = '';
    this.createProjectErrorOfficialenddate = '';
    this.createProjectErrorPostalcode = '';
    this.createProjectErrorCity = '';
    this.createProjectErrorState = '';
    this.createProjectErrorWeblinkone = '';
    this.createProjectErrorWeblinktwo = '';
    this.createProjectErrorWeblinkthree = '';
    this.createProjectErrorState = '';
    this.CreatDocumentsErrorDocuments = '';
    this.CreatDocumentsErrorDocumentsname = '';
    this.createProjectError = '';
    this.UpdateProjectForm.reset();
  }

  getClientList(searchTerm: any = '') {
    let data: any;

    if (searchTerm && searchTerm.length >= 2) data = { search: searchTerm };
    this.clientService.listByFilter(data).subscribe({
      next: (response) => {
        this.clientList = response.clients.data;
      },
      error: (error: any) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  getProjectTypeList() {
    this.projectService.listTypeAll().subscribe({
      next: (response) => {
        this.projectTypeList = response.project_types;
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  getProjectStatusList() {
    if (this.projectStatusList.length == 0) {
      this.projectService.listStatus().subscribe({
        next: (response) => {
          this.projectStatusList = response.project_statuses;
        },
        error: (error) => {
          this.notificationService.showError(error.message);
        },
      });
    }
  }

  getStateList() {
    this.projectService.listStateAll().subscribe({
      next: (response) => {
        this.stateList = response.states;
      },
      error: (error: any) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  getProjectList(
    data1: any,

    data = {
      is_public: this.is_public,
      page: this.pageNumber,
      sort_field: this.currentSortedColumn,
      sort_method: this.currentSortedMethod,
      column_name: this.sortedColumn,
      search: this.currentSearchValue,
      apply_filter: this.currentAppliedFilter,
      user_id: null,
    },
    reset: boolean = false
  ) {
    // const innerHeight = window.innerHeight;
    // if(innerHeight>700 && innerHeight < 800){
    //   this.pageSize=15;
    // }
    if (data1) {
      data.sort_field = data1.sort_field;
      data.sort_method = data1.sort_method;
      data.column_name = data1.column_name;
    }
    //data={page :this.pageNumber, sort_field: string = '', sort_method: string ='', column_name: string=''}
    if (!reset) {
      if (this.getSearchList) {
        // this.currentSearchValue = data.search;
        this.getSearchList.searches = data;
        this.storageService.setValue(
          'listSettings',
          JSON.stringify(this.getSearchList)
        );
      } else
        this.storageService.setValue(
          'listSettings',
          JSON.stringify({ searches: data })
        );
    } else {
      // data.search = "";
      data.page = 1;
      // data.is_public = this.is_public;
    }
    let userRole = this.storageService.getValue(StorageKey.currentUserRoleId);
    let currentUserId: any = this.storageService.getValue(
      StorageKey.currentUserid
    );

    const listSetting = localStorage.getItem('listSettings');
    let parsedList: any;
    if (listSetting) {
      parsedList = JSON.parse(listSetting).searches;
    }

    if (parsedList) {
      parsedList.sort_field = parsedList.sort_field || '';
      parsedList.sort_method = parsedList.sort_method || '';
    }

    if (parsedList) {
      if (parsedList.sort_method && parsedList.sort_method !== '') {
        if (
          (!this.lastSortedField && parsedList.sort_field) ||
          this.lastSortedField !== parsedList.sort_field ||
          this.lastSortedDirection !== parsedList.sort_method ||
          this.isSearchClicked ||
          this.isClearClicked ||
          this.resetButtonClicked
        ) {
          data.page = 1;
        }
      }
    }

    if (userRole == '2') {
      //data.user_id = currentUserId;
      data.user_id = currentUserId;
    }

    this.projectService.listByFilter(data).subscribe({
      next: (response) => {
        if (
          this.isSearchClicked ||
          this.isClearClicked ||
          this.resetButtonClicked
        ) {
          this.projectList = response.projects.data;
        } else {
          if (
            (!this.lastSortedField && parsedList.sort_field) ||
            (this.lastSortedField &&
              this.lastSortedField !== parsedList.sort_field) ||
            (this.lastSortedDirection &&
              this.lastSortedDirection !== parsedList.sort_method)
          ) {
            this.projectList = response.projects.data;
          } else {
            this.projectList = [...this.projectList, ...response.projects.data];
          }
        }

        if (parsedList && parsedList.sort_field) {
          this.lastSortedField = parsedList.sort_field;
          this.lastSortedDirection = parsedList.sort_method;
        }
        this.isSearchClicked = false;
        this.isClearClicked = false;
        this.resetButtonClicked = false;

        this.pageNumber = response.projects.current_page;
        this.totalRows = response.projects.total;
        this.pager = this.pagerService.getPager(
          this.totalRows,
          this.pageNumber
        );
        // console.log(this.projectList,"list")

        if (data.sort_field) {
          this.iconVisible = false;
          this.staticarrowsVisible = true;
          this.currentSortedColumn = data.column_name;
          this.sortedColumn = data.column_name;
          if (data.sort_method === 'desc') {
            this.iconVisibleDesc = true;
            this.iconVisibleAsc = false;
            this.currentSortedMethod = 'desc';
          }
          if (data.sort_method === 'asc') {
            this.iconVisibleAsc = true;
            this.iconVisibleDesc = false;
            this.currentSortedMethod = 'asc';
          }
          this.sortfield = response.projects.sort_field;
          this.sortmethod = response.projects.sort_method;
        }
      },
      error: (error) => {
        console.log(error);
        //this.notificationService.showError(error.message);
      },
    });
  }

  getUsersList() {
    this.userService.listAll().subscribe({
      next: (response) => {
        this.userList = response.users.data;
      },
      error: () => {},
    });
  }

  onUserSearch(term: any) {
    let searchValue = term.term;
    // console.log(searchValue);
    if (true) {
      // searchValue.length>=2
      let data = { search: searchValue };
      this.userService.searchUserwiseList(data).subscribe({
        next: (response: any) => {
          // console.log(response);
          this.userList = response.users.data;
          this.userList = this.userList.filter(
            (ele: any) => ele.is_active == 1
          );
          this.userList.forEach((ele: any) => {
            ele['dropdown'] =
              ele?.name + (ele.user?.name ? ' [' + ele.user?.name + ']' : '');
          });
        },
        error: (err) => {},
      });
    }
  }

  togglePinned(pl: any) {
    pl.is_pinned = !pl.is_pinned;
    if (pl.is_pinned) {
      this.projectService.pinProject(pl.id).subscribe({
        next: (data: any) => {
          this.notificationService.showSuccess(data.message);
          this.onLoad();
        },
        error: (error: any) => {
          this.notificationService.showError(error.message);
        },
      });
    } else {
      this.projectService.unpinProject(pl.id).subscribe({
        next: (data: any) => {
          this.notificationService.showSuccess(data.message);
          this.onLoad();
        },
        error: (error: any) => {
          this.notificationService.showError(error.message);
        },
      });
    }
  }

  applyFilter(page: number = 1) {
    let apply_filter = '';
    this.pageNumber = page;

    let userRole = this.storageService.getValue(StorageKey.currentUserRoleId);
    let currentUserId = this.storageService.getValue(StorageKey.currentUserid);
    if (userRole == '2') {
      //data.user_id = currentUserId;
      if (apply_filter != '') apply_filter += '&';
      apply_filter += 'user_id=' + currentUserId;
    }

    if (this.FilterForm.controls['f_project_status'].value) {
      if (apply_filter != '') apply_filter += '&';
      apply_filter +=
        'project_status_id=' +
        this.FilterForm.controls['f_project_status'].value;
    }

    if (this.FilterForm.controls['f_project_type'].value) {
      if (apply_filter != '') apply_filter += '&';

      apply_filter +=
        'project_type_id=' + this.FilterForm.controls['f_project_type'].value;
    }

    if (this.FilterForm.controls['f_client'].value) {
      if (apply_filter != '') apply_filter += '&';
      apply_filter += 'client_id=' + this.FilterForm.controls['f_client'].value;
    }

    if (this.FilterForm.controls['f_city'].value) {
      if (apply_filter != '') apply_filter += '&';
      apply_filter += 'search_city=' + this.FilterForm.controls['f_city'].value;
    }

    if (this.FilterForm.controls['f_state'].value) {
      if (apply_filter != '') apply_filter += '&';
      apply_filter +=
        'search_state=' + this.FilterForm.controls['f_state'].value;
    }
    this.currentAppliedFilter = apply_filter;

    let data = {
      page: this.pageNumber,
      sort_field: this.currentSortedColumn,
      sort_method: this.currentSortedMethod,
      column_name: this.sortedColumn,
      apply_filter: apply_filter,
      is_public: this.is_public,
    };
    this.getProjectList(data);
  }

  mouseEnter(columnname: any) {
    this.iconVisible = true;
    this.staticarrowsVisible = false;
    // this.currentSortedColumn = columnname;
    this.mouseOnColumnName = columnname;
    if (columnname == 'name' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'project_status_id' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'project_type_id' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'days_open' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'candidates_count' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'client_id' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'city' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
  }

  mouseLeave(columnname: any) {
    this.iconVisible = false;
    this.staticarrowsVisible = true;
  }

  public searchData(value: any) {
    this.isSearchClicked = true;
    this.currentSearchValue = value;
    this.pageNumber = 1;
    this.getProjectList({
      page: this.pageNumber,
      sort_field: this.currentSortedColumn,
      sort_method: this.currentSortedMethod,
      column_name: this.sortedColumn,
      search: value,
      is_public: this.is_public,
    });
  }

  public showFilter() {
    this.showFilterOptions = !this.showFilterOptions;
  }

  public clearFilterForm() {
    this.isClearClicked = true;
    this.currentAppliedFilter = '';
    this.FilterForm.reset();
    this.showFilterOptions = false;
    this.getProjectList({});
  }

  setPage(page: number) {
    this.pageNumber = page;
    this.applyFilter(page);
    // this.getProjectList({ page: this.pageNumber, sort_field: this.currentSortedColumn, sort_method: this.currentSortedMethod, column_name: this.sortedColumn });
  }

  showProject(id: number) {
    this.router.navigate(['/search/' + id]);
  }

  openDeleteModal(content: any, deleteId: number, value: any) {
    this.selectDeletedProject = value.name;
    this.modalservice
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          this.deleteProject(deleteId);
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  deleteProject(deleteId: number) {
    this.projectService.deleteProjectData(deleteId).subscribe({
      next: (response) => {
        //  this.tagList = response.tags.data;
        this.notificationService.showSuccess(response.message);
        this.getProjectList({});
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  onClientSearch(term: any) {
    let searchValue = term.term;
    if (true) {
      // searchValue.length>=2
      let data = { search_name: searchValue };
      this.clientService.searchClientwiseList(data).subscribe({
        next: (response: any) => {
          // console.log(response);
          this.clientsList = response.clients.data;
          this.clientsList = this.clientsList.filter(
            (ele: any) => ele.is_active == 1
          );
        },
        error: (err: any) => {},
      });
    }
  }

  getClientsList() {
    this.clientService.listAll().subscribe({
      next: (response) => {
        this.clientsList = response.clients.data;
        this.clientsList = this.clientsList.filter(
          (ele: any) => ele.is_active == 1
        );
      },
      error: (error) => {},
    });
  }

  getStatusofprojectList() {
    if (this.statusofprojectList.length == 0) {
      this.projectService.statusofProjectList().subscribe({
        next: (response) => {
          this.statusofprojectList = response.project_statuses;
        },
        error: (error) => {
          this.notificationService.showError(error.message);
        },
      });
    }
  }

  resetListSettings() {
    this.resetButtonClicked = true;
    this.lastSortedField = '';
    this.pageNumber = 1;
    let listSettings: any = this.storageService.getValue(
      StorageKey.listSettings
    );
    if (listSettings !== 'undefined' && listSettings)
      this.getSearchList = JSON.parse(listSettings);
    if (this.getSearchList && this.getSearchList.searches) {
      this.lastSortedDirection = this.getSearchList.searches.sort_method;
      delete this.getSearchList.searches;
      this.storageService.setValue(
        'listSettings',
        JSON.stringify(this.getSearchList)
      );
    }

    this.iconVisible = true;
    this.currentSearchValue = '';
    this.currentSortedColumn = '';
    this.currentSearchValue = '';
    this.currentAppliedFilter = '';
    this.FilterForm.reset();
    this.showFilterOptions = false;
    this.getProjectList({}, undefined, true);
  }

  // for documents
  attachFiles(event: any) {
    this.files = event.target.files[0];
    this.fileName = this.files.name;
    this.filePath = event.target.value;
  }

  UploadDocuments() {
    this.CreatDocumentsErrorDocuments = '';
    this.CreatDocumentsErrorDocumentsname = '';

    this.uploadsubmitted = true;
    if (!this.files) {
      return;
    }
    const formData: FormData = new FormData();
    formData.append('name', this.fileName);
    formData.append('document', this.files);

    // this.projectapicall2 = true;
    this.projectService.createDocument(this.ProjectdocId, formData).subscribe({
      next: (response: any) => {
        // this.onLoad();
        // this.modalService.dismissAll();
        this.notificationService.showSuccess(response.message);
        // this.projectapicall2 = false;
        this.uploadsubmitted = false;
        this.getDocumentData();
        this.UpdateProjectForm.controls['document'].setValue(null);
      },
      error: (error) => {
        // this.projectapicall2 = false;
        // this.CreatDocumentsError = error.error.message;
        switch (error.status) {
          case 422:
            if (error.error.document) {
              this.CreatDocumentsErrorDocuments = error.error.document[0];
            }
            if (error.error.name) {
              this.CreatDocumentsErrorDocumentsname = error.error.name[0];
            }
            break;
          default:
          //Nothing
        }
        // this.notificationService.showError(error.message);
      },
    });
  }

  deleteDocuments(deleteId: any) {
    this.projectService.deleteDocument(this.ProjectdocId, deleteId).subscribe({
      next: (response: any) => {
        this.notificationService.showSuccess(response.message);
        this.getDocumentData();
      },
      error: (err: any) => {},
    });
  }

  getDocumentData() {
    this.projectService.getDocumentData(this.ProjectdocId).subscribe({
      next: (response) => {
        this.documentData = response.project.documents;
      },
      error: (error: any) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  Updatejobposting() {
    this.submitted = true;
    if (this.updateJobPostingForm.invalid) {
      return;
    }

    let UpdatedProjectDetails = {
      id: this.updateJobPostingForm.controls['projectId'].value,
      name: this.updateJobPostingForm.controls['name'].value,
      city: this.updateJobPostingForm.controls['city'].value,
      state: this.updateJobPostingForm.controls['state'].value,
      project_status_id: Number(
        this.updateJobPostingForm.controls['project_status_id'].value
      ),
      description: this.updateJobPostingForm.controls['description'].value,
      is_public: this.updateJobPostingForm.controls['is_public'].value ?? 1,
      is_publish:
        this.updateJobPostingForm.controls['is_publish'].value ?? null,
      client_id: this.updateJobPostingForm.controls['clientname'].value,
    };
    this.projectService
      .updateProjectData(UpdatedProjectDetails.id, UpdatedProjectDetails)
      .subscribe({
        next: (response: any) => {
          this.modalservice.dismissAll();
          this.updateJobPostingForm.reset();
          // this.notificationService.showSuccess(response.message);
          this.notificationService.showSuccess(
            'Job posting updated successfully.'
          );
          this.projectapicall = false;
          this.submitted = false;
          for (let i = 0; i < this.projectList.length; i++) {
            if (this.projectList[i].id == UpdatedProjectDetails.id) {
              this.projectList[i] = response.project;
              break;
            }
          }
        },
        error: (error: any) => {
          this.projectapicall = false;
          this.createProjectError = error.error.message;
          switch (error.status) {
            case 422:
              if (error.error.name) {
                this.createProjectErrorName = error.error.name[0];
              }
              if (error.error.project_status_id) {
                this.createProjectErrorProjectstatusid =
                  error.error.project_status_id[0];
              }
              if (error.error.city) {
                this.createProjectErrorCity = error.error.city[0];
              }
              if (error.error.state) {
                this.createProjectErrorState = error.error.state[0];
              }
              if (error.error.description) {
                this.createProjectErrorDescription = error.error.description[0];
              }
              break;

            default:
          }
        },
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from the route subscription to avoid memory leaks
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
}
